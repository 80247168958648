import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ROUTE } from 'routes/constants';
import { useLoginSoftPhoneMutation } from 'services/softphone/softphone.api';
import { setAuthenticated } from 'store/features/auth/auth.slice';
import { setAuthenticatedSoftPhone } from 'store/features/sip-auth/sip-auth.slice';
import { LoginSoftPhoneDto } from 'types/dto/softphone/login-softphone.dto';

export function useLoginSoftPhone() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [login, { isLoading, error }] = useLoginSoftPhoneMutation();

  const onLoginSoftPhoneHandle = async (values: LoginSoftPhoneDto) => {
    try {
      login(values)
        .unwrap()
        .then((res: any) => {
          console.log(res, 'đăng nhập world phone nè');
          // dispatch(setProfile(res.profile));
          dispatch(setAuthenticatedSoftPhone(true));
        })
        .catch((err: any) => console.error(err, 'hihi err nè'));
    } catch (err) {
      dispatch(setAuthenticatedSoftPhone(false));
    }
  };
  return {
    isLoading,
    error,
    onLoginSoftPhoneHandle
  };
}
