import { useGetTicketDetailQuery } from "services/tickets";

export function useTicketDetail(ticketId: number) {
    const { data: ticketInProgressRes, isLoading } = useGetTicketDetailQuery(ticketId!, {
        skip: !ticketId,
        refetchOnMountOrArgChange: true
    });

    return {
        ticketDetail: ticketInProgressRes?.data,
        isLoading
    }
}