import { CheckPermissionLayout, PermissionLayout, UserLayout } from 'layouts';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import { PERMISSION } from 'utils';
import { ROUTE } from './constants';

export const accessSystemRoutes: RouteObject[] = [
  {
    element: <CheckPermissionLayout permission={[PERMISSION.ACCESS_SYSTEM]} />,
    children: [
      {
        element: <UserLayout />,
        children: [
          {
            path: ROUTE.USERS,
            element: <CheckPermissionLayout permission={[PERMISSION.READ_USER]} />,
            children: [
              {
                index: true,
                Component: lazy(() => import('pages/Users'))
              }
            ]
          },

          {
            path: ROUTE.USER_GROUPS,
            element: <CheckPermissionLayout permission={[PERMISSION.READ_USER_GROUP]} />,
            children: [
              {
                index: true,
                Component: lazy(() => import('pages/UserGroups'))
              }
            ]
          }
        ]
      },
      {
        path: `${ROUTE.USERS}/:userId`,
        Component: lazy(() => import('pages/User'))
      },
      {
        path: ROUTE.ORGANIZATION_UNITS,
        element: <CheckPermissionLayout permission={[PERMISSION.READ_ORGANIZATION_UNIT]} />,
        children: [
          {
            index: true,
            Component: lazy(() => import('pages/OrganizationUnits'))
          }
        ]
      },
      {
        element: <PermissionLayout />,
        children: [
          {
            path: ROUTE.ROLES,
            element: <CheckPermissionLayout permission={[PERMISSION.READ_ROLE]} />,
            children: [
              {
                index: true,
                Component: lazy(() => import('pages/Roles'))
              }
            ]
          },
          {
            path: ROUTE.PERMISSIONS,
            element: <CheckPermissionLayout permission={[PERMISSION.UPDATE_PERMISSION]} />,
            children: [
              {
                index: true,
                Component: lazy(() => import('pages/Permissions'))
              }
            ]
          }
        ]
      },
      {
        path: ROUTE.SERVICE_TYPES,
        element: <CheckPermissionLayout permission={[PERMISSION.READ_SERVICE_TYPE]} />,
        children: [
          {
            index: true,
            Component: lazy(() => import('pages/ServiceTypes'))
          }
        ]
      },
      {
        path: ROUTE.TICKET_SAMPLES,
        element: <CheckPermissionLayout permission={[PERMISSION.READ_TICKET_SAMPLE]} />,
        children: [
          {
            index: true,
            Component: lazy(() => import('pages/TicketSamples'))
          }
        ]
      },
      {
        path: ROUTE.RATING_SYSTEM,
        element: <CheckPermissionLayout permission={[PERMISSION.UPDATE_SYSTEM_CONFIG]} />,
        children: [
          {
            index: true,
            Component: lazy(() => import('pages/RatingSystem'))
          }
        ]
      },
      {
        path: ROUTE.API_SHARING_PARTNERS,
        element: <CheckPermissionLayout permission={[PERMISSION.READ_API_SHARING_PARTNER]} />,
        children: [
          {
            index: true,
            Component: lazy(() => import('pages/ApiSharingPartners'))
          }
        ]
      }
    ]
  }
];
