import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { usersApi } from 'services';

interface AuthState {
  isAuthenticated?: boolean;
  token?: string;
}

const initialState: AuthState = {
  isAuthenticated: false,
  token: ''
};

const authSoftPhoneSlice = createSlice({
  name: 'authSoftPhone',
  initialState,
  reducers: {
    setAuthenticatedSoftPhone: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    setSoftPhoneToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    }
  }
});

export const { setAuthenticatedSoftPhone, setSoftPhoneToken } = authSoftPhoneSlice.actions;

export default authSoftPhoneSlice.reducer;
