import React from 'react';
import { Divider, Typography } from 'antd';
import { requestform } from 'messages';
import { TicketDetail } from 'types';

import customerRequest from '../../assets/svg/customer-request.svg';
import requestContent from '../../assets/svg/request-content.svg';

import img from '../../assets/images/logo.png';
import { ServerFileList } from 'components/common';
interface ticketCreateInformation {
  requestItem: TicketDetail | null;
}

const RequestDetailMainInformation: React.FC<ticketCreateInformation> = ({ requestItem }) => {
  const fieldValues = requestItem?.files?.map((file) => file.fileId) || [];
  return (
    <div>
      {/* Customer Information */}
      <div>
        <div className='flex items-center space-x-3'>
          <img src={img} className='h-8 w-8' alt='' />
          <Typography.Text className='text-[18px]/[22px] font-semibold text-[#141414]'>
            {requestform.customerInformationTitle}
          </Typography.Text>
        </div>
        <div className='space-y-2 pt-[20px]'>
          <div className='flex space-x-4'>
            <div className='w-[160px]'>
              <Typography.Text className='text-[16px]/[16px] text-[#6C737E]'>
                {requestform.customerName}
              </Typography.Text>
            </div>
            <Typography.Text className='text-[16px]/[20px] text-[#141414]'>
              {requestItem?.customerRequestName ? requestItem.customerRequestName : '-'}
            </Typography.Text>
          </div>

          <div className='flex space-x-4'>
            <div className='w-[160px]'>
              <Typography.Text className='text-[16px]/[16px] text-[#6C737E]'>
                {requestform.customerPhone}
              </Typography.Text>
            </div>
            <Typography.Text className='text-[16px]/[20px] text-[#141414]'>
              {requestItem?.customerRequestPhoneNumber ? requestItem.customerRequestPhoneNumber : '-'}
            </Typography.Text>
          </div>

          <div className='flex space-x-4'>
            <div className='w-[160px]'>
              <Typography.Text className='text-[16px]/[16px] text-[#6C737E]'>
                {requestform.customerAddress}
              </Typography.Text>
            </div>
            <Typography.Text className='text-[16px]/[20px] text-[#141414]'>
              {requestItem?.customerRequestAddress ? requestItem.customerRequestAddress : '-'}
            </Typography.Text>
          </div>
        </div>
      </div>
      <Divider className='mt-[20px] bg-[#D4D8DE]' />

      {/* Request Information  */}
      <div>
        <div className='flex items-center space-x-3'>
          <img src={customerRequest} alt='' />
          <Typography.Text className='text-[18px]/[22px] font-semibold text-[#141414]'>
            {requestform.customerRequestInformationTitle}
          </Typography.Text>
        </div>
        <div className='space-y-2 pt-[20px]'>
          <div className='flex space-x-4'>
            <div className='w-[160px]'>
              <Typography.Text className='text-[16px]/[16px] text-[#6C737E]'>
                {requestform.customerRequestName}
              </Typography.Text>
            </div>
            <Typography.Text className='text-[16px]/[20px] text-[#141414]'>
              {requestItem?.customerRequestName ? requestItem.customerRequestName : '-'}
            </Typography.Text>
          </div>
          <div className='flex space-x-4'>
            <div className='w-[160px]'>
              <Typography.Text className='text-[16px]/[16px] text-[#6C737E]'>
                {requestform.customerRequestPhone}
              </Typography.Text>
            </div>
            <Typography.Text className='text-[16px]/[20px] text-[#141414]'>
              {requestItem?.customerRequestPhoneNumber ? requestItem.customerRequestPhoneNumber : '-'}
            </Typography.Text>
          </div>
          <div className='flex space-x-4'>
            <div className='w-[160px]'>
              <Typography.Text className='text-[16px]/[16px] text-[#6C737E]'>
                {requestform.numberOfCalls}
              </Typography.Text>
            </div>
            <Typography.Text className='text-[16px]/[20px] text-[#141414]'>
              {requestItem?.numOfRequests ? requestItem.numOfRequests : '-'}
            </Typography.Text>
          </div>
          <div className='flex space-x-4'>
            <div className='w-[160px]'>
              <Typography.Text className='text-[16px]/[16px] text-[#6C737E]'>
                {requestform.customerRequestAddress}
              </Typography.Text>
            </div>
            <Typography.Text className='text-[16px]/[20px] text-[#141414]'>
              {requestItem?.customerRequestAddress ? requestItem.customerRequestAddress : '-'}
            </Typography.Text>
          </div>
        </div>
        <div className='space-y-2 pt-[20px]'>
          <div className='flex space-x-4'>
            <div className='w-[160px]'>
              <Typography.Text className='text-[16px]/[16px] text-[#6C737E]'>
                {requestform.customerRequestCIN}
              </Typography.Text>
            </div>
            <Typography.Text className='text-[16px]/[20px] text-[#141414]'>
              {requestItem?.customerRequestCicNumber ? requestItem.customerRequestCicNumber : '-'}
            </Typography.Text>
          </div>
          <div className='flex space-x-4'>
            <div className='w-[160px]'>
              <Typography.Text className='text-[16px]/[16px] text-[#6C737E]'>{requestform.dateOfIssue}</Typography.Text>
            </div>
            <Typography.Text className='text-[16px]/[20px] text-[#141414]'>
              {requestItem?.customerRequestCicIssueDate ? requestItem.customerRequestCicIssueDate : '-'}
            </Typography.Text>
          </div>
          <div className='flex space-x-4'>
            <div className='w-[160px]'>
              <Typography.Text className='text-[16px]/[16px] text-[#6C737E]'>
                {requestform.placeOfIssue}
              </Typography.Text>
            </div>
            <Typography.Text className='text-[16px]/[20px] text-[#141414]'>
              {requestItem?.customerRequestCicIssuePlace ? requestItem.customerRequestCicIssuePlace : '-'}
            </Typography.Text>
          </div>
        </div>
      </div>
      <Divider className='mt-[20px] bg-[#D4D8DE]' />

      {/* File  */}
      <div>
        <Typography.Text className='text-[16px]/[16px] text-[#141414]'>File đính kèm</Typography.Text>
        <div className='mt-3 flex space-x-1'>
          <ServerFileList
            isPreview
            fileList={fieldValues}
            itemProps={{ className: 'max-w-[40px] h-[60px]', showInfo: false }}
          ></ServerFileList>
        </div>
      </div>
      <Divider className='mt-[20px] bg-[#D4D8DE]' />

      {/* Request Content */}
      <div>
        <div className='flex items-center space-x-3'>
          <img src={requestContent} alt='' />
          {/* <RequestContentIcon /> */}
          <Typography.Text className='text-[18px]/[22px] font-semibold text-[#141414]'>
            {requestform.requestContentTitle}
          </Typography.Text>
        </div>

        <div className='mt-[20px] max-w-[800px] rounded-lg bg-[#DDDDDD] p-3'>
          <Typography.Text className='text-[16px]/[20px] text-[#141414]'>
            {requestItem?.content ? requestItem.content : '-'}
          </Typography.Text>
        </div>
      </div>
      <Divider className='mt-[20px] bg-[#D4D8DE]' />
    </div>
  );
};

export default RequestDetailMainInformation;
