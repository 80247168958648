import { Card, Input, message, Typography } from 'antd';
import { CollaborationRequest, CollaborationTicket, OrganizationUnitChildren, TicketDetail } from 'types';
import { requestform } from 'messages';
import { SearchOutlined } from '@ant-design/icons';
import { AngleRightBracket } from 'assets';
import { useEffect, useState } from 'react';
import { GetOrganizationUnitChildren } from 'hooks/request-form';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { usePatchCollaborationTicketsMutation } from 'services';

interface ticketDetail {
  requestItem: TicketDetail | null;
  processingContent: string;
  cancelDetailModal: () => void;
}

const ModalTransferUnit: React.FC<ticketDetail> = ({ requestItem, processingContent, cancelDetailModal }) => {
  const [originalList, setOriginalList] = useState<OrganizationUnitChildren[]>([]);
  const [filteredList, setFilteredList] = useState(originalList);

  const { data: organizationUnitChildrenList } = GetOrganizationUnitChildren(
    Number(requestItem?.organizationUnit.organizationUnitId || 0)
  );
  const profile = useSelector((state: RootState) => state.users.userProfile);
  const [collaboration] = usePatchCollaborationTicketsMutation();

  // const
  useEffect(() => {
    setOriginalList(organizationUnitChildrenList);
    setFilteredList(originalList);
  }, [originalList]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = e.target.value.toLowerCase();
    const filtered = originalList.filter((unit) => unit.name.toLowerCase().includes(searchText));
    setFilteredList(filtered);
  };
  const sendTransferUnitRequestTickets = (toCollaborationUnitId: number) => {
    const fromOrganizationUnitId = profile?.organizationUnitId || 0;
    const ticketId = requestItem?.ticketId || 0;
    const collaborationDetail: CollaborationTicket = {
      processingContent,
      fromOrganizationUnitId,
      toCollaborationUnitId
    };

    const collaborationRequest: CollaborationRequest = {
      collaborationDetail,
      ticketId
    };

    collaboration(collaborationRequest)
      .then((res) => {
        message.success('Chuyển phiếu yêu cầu thành công', 2);
        console.log(res);
      })
      .catch((err) => {
        message.error('Chuyển phiếu yêu cầu thất bại', 2);
        console.log(err);
      });
  };

  return (
    <Card className='confirm-modal mx-auto bg-[#F5F5F7] py-4 text-center'>
      <div className='ml-6 flex justify-start'>
        <Typography.Text className='text-[26px]/[34px] font-bold text-[#141414]'>
          {requestform.chooseOrganizationUnit}
        </Typography.Text>
      </div>
      <div className='mx-6 my-6'>
        <Input
          className='h-[40px] w-full bg-[#FFFFFF]'
          placeholder={requestform.inputInformation}
          prefix={<SearchOutlined className='text-[#B2B9C4]' />}
          onChange={handleSearch}
          variant='borderless'
        />
      </div>

      <div className='mx-6 text-left'>
        {filteredList.length > 0 ? (
          filteredList.map((unit) => (
            <button
              key={unit.organizationUnitId}
              onClick={() => sendTransferUnitRequestTickets(unit.organizationUnitId)}
              className='flex w-full justify-between rounded-[5px] border-b py-[10px] text-left hover:bg-[#efefef]'
            >
              <Typography.Text className='text-[16px]/[20px] font-bold text-[#181818]'>{unit.name}</Typography.Text>
              <Typography.Text>
                <AngleRightBracket />
              </Typography.Text>
            </button>
          ))
        ) : (
          <Typography.Text className='text-gray-500'>Không tìm thấy kết quả</Typography.Text>
        )}
      </div>
    </Card>
  );
};

export default ModalTransferUnit;
