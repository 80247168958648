import { Card, Divider, Dropdown, Menu, message, Typography } from 'antd';
import { requestform } from 'messages';
import React, { useState } from 'react';
import { TicketDetail } from 'types';

import exchangeSVG from '../../assets/svg/exchange.svg';
import { DownOutlined } from '@ant-design/icons';
import { usePatchReceptionTicketMutation } from 'services';

interface ticketDetail {
  closeModalCMIS: () => void;
  requestItem: TicketDetail | null;
}
interface ExchangeSelection {
  key: number;
  content: string;
  transfer: string;
}
const exchangeSelections: ExchangeSelection[] = [
  { key: 1, content: 'Chuyển phiếu yêu cầu đến Cmis', transfer: 'cmis' }
];

const ModalTransferCmis: React.FC<ticketDetail> = ({ requestItem, closeModalCMIS }) => {
  const [selectedOption, setSelectedOption] = useState<ExchangeSelection>(exchangeSelections[0]);

  const handleMenuClick = (option: ExchangeSelection) => {
    setSelectedOption(option);
  };

  const [patchReception] = usePatchReceptionTicketMutation();
  const receptionTicketAction = () => {
    if (requestItem?.ticketId !== undefined) {
      patchReception(requestItem.ticketId)
        .unwrap()
        .then((res) => {
          // window.location.reload();
          closeModalCMIS();
          message.success('Tiếp nhận thành công phiếu yêu cầu', 2);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.warn('Ticket ID is undefined, cannot patch reception.');
    }
  };

  const menu = (
    <Menu>
      {exchangeSelections.map((option, index) => (
        <Menu.Item
          key={index}
          onClick={() => handleMenuClick(option)}
          className='flex h-[40px] items-center px-4 hover:bg-[#F5F4F6]'
        >
          <Typography.Text>{option.content}</Typography.Text>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Card className='confirm-modal mx-auto bg-[#F5F5F7] py-4 text-center'>
      <div className='py-10'>
        <div className='space-y-4 py-8'>
          <div className='flex justify-center'>
            <img src={exchangeSVG} alt='' />
          </div>{' '}
          <div>
            <Typography.Text className='text-[22px]/[26px] font-semibold text-[#141414]'>
              {requestform.confirmChangeCMIS}
            </Typography.Text>
          </div>
          <div>
            <Typography.Text>{requestform.confirmChangeCMISContent}</Typography.Text>
          </div>{' '}
        </div>{' '}
        <div className='mx-6'>
          <Dropdown overlay={menu} trigger={['click']}>
            <div className='flex h-[52px] w-full cursor-pointer items-center justify-between rounded-lg border border-[#EBEBED] bg-[#FFFFFF] px-3 py-2'>
              <Typography.Text>{selectedOption.content}</Typography.Text>
              <DownOutlined className='h-2 w-[10px]' />
            </div>
          </Dropdown>
        </div>
      </div>
      <Divider />
      <div className='mx-8 text-right'>
        <button
          onClick={() => {
            receptionTicketAction();
          }}
          className='rounded-[5px] bg-[#1564E8] px-9 py-[10px]'
        >
          <Typography.Text className='text-[#FFFFFF]'>{requestform.confirmButton}</Typography.Text>
        </button>
      </div>
    </Card>
  );
};

export default ModalTransferCmis;
