import { createApi } from '@reduxjs/toolkit/query/react';
import { ChangeStatus, CollaborationRequest, InprogressTickets, OrganizationUnitChildren, RequestData, ResponseDto, ResponsePagingDto, TicketDetail, ticketType, TransferToUnit } from 'types';
import axiosBaseQuery from 'utils/base-api';

export const requestForm = createApi({
    reducerPath: 'requestForm',
    tagTypes: ['pending', 'inprogress', 'collaboration', 'completed'],
    baseQuery: axiosBaseQuery,
    endpoints: (builder) => ({
        getPendingTicket: builder.query<ResponsePagingDto<ticketType>, number>({
            query: (pageSize) => ({
                url: '/tickets/dienluc/pending-reception',
                method: 'get',
                params: { pageSize }
            }),
            providesTags: (result) =>
                result && result.data.rows.length > 0
                    ? result.data.rows.map(() => ({
                        type: 'pending',
                    }))
                    : ['pending']
        }),
        getInProgressTicket: builder.query<ResponsePagingDto<ticketType>, number>({
            query: (pageSize) => ({
                url: '/tickets/dienluc/in-progress',
                method: 'get',
                params: { pageSize }
            }),
            providesTags: (result) =>
                result && result.data.rows.length > 0
                    ? result.data.rows.map(() => ({
                        type: 'inprogress',
                    }))
                    : ['inprogress']
        }),
        getCollaborationTicket: builder.query<ResponsePagingDto<ticketType>, number>({
            query: (pageSize) => ({
                url: '/tickets/dienluc/collaboration',
                method: 'get',
                params: { pageSize }
            }),
            providesTags: (result) =>
                result && result.data.rows.length > 0
                    ? result.data.rows.map(() => ({
                        type: 'collaboration',
                    }))
                    : ['collaboration']
        }),
        getCompletedTicket: builder.query<ResponsePagingDto<ticketType>, number>({
            query: (pageSize) => ({
                url: '/tickets/dienluc/completed',
                method: 'get',
                params: { pageSize }
            }),
            providesTags: (result) =>
                result && result.data.rows.length > 0
                    ? result.data.rows.map(() => ({
                        type: 'completed',
                    }))
                    : ['completed']
        }),

        getTicketDetail: builder.query<ResponsePagingDto<TicketDetail>, number>({
            query: (ticketDetail) => ({
                url: `/tickets/detail/${ticketDetail}`,
                method: 'get',
                // params: { ticketDetail }
            }),
        }),

        getTicketList: builder.query<ResponsePagingDto<TicketDetail>, RequestData>({
            query: (requestData) => {
                const params = new URLSearchParams();
                if (requestData.pageIndex) params.append('pageIndex', requestData.pageIndex.toString());
                if (requestData.pageSize) params.append('pageSize', requestData.pageSize.toString());
                if (requestData.fromDate) params.append('fromDate', requestData.fromDate.toString());
                if (requestData.toDate) params.append('toDate', requestData.toDate.toString());

                return {
                    url: `/tickets/dienluc/${requestData.status}`,
                    method: 'get',
                    params: params
                };
            },
        }),

        //Organization Unit 
        getOrganizatioUnitChildren: builder.query<ResponsePagingDto<OrganizationUnitChildren>, number>({
            query: (organizationUnitId) => {
                return {
                    url: `/organization_units/${organizationUnitId}/children`,
                    method: 'get',
                };
            },
        }),

        // Patch API
        patchTransferTo: builder.mutation<ResponseDto<null>, TransferToUnit>({
            query: ({ toUnit, ticketId }) => {
                return {
                    url: `/tickets/dtv/transfer_to_${toUnit}/${ticketId}`,
                    method: 'patch',
                };
            },
        }),
        patchReceptionTicket: builder.mutation<ResponseDto<ChangeStatus>, number>({
            query: (ticketId) => {
                return {
                    url: `/tickets/dienluc/reception/${ticketId}`,
                    method: 'patch',
                };
            },
        }),
        patchCompleteTicket: builder.mutation<ResponseDto<ChangeStatus>, number>({
            query: (ticketId) => {
                return {
                    url: `/tickets/dienluc/complete/${ticketId}`,
                    method: 'patch',
                };
            },
        }),
        patchInprogressTicket: builder.mutation<ResponseDto<ChangeStatus>, InprogressTickets>({
            query: ({ ticketId, processingContent }) => {
                return {
                    url: `/tickets/dienluc/in-progress/${ticketId}`,
                    method: 'patch',
                    data: { processingContent }
                };
            },
        }),
        patchCollaborationTickets: builder.mutation<ResponseDto<ChangeStatus>, CollaborationRequest>({
            query: ({ ticketId, collaborationDetail }) => {
                return {
                    url: `/tickets/dienluc/collaboration/${ticketId}`,
                    method: 'patch',
                    data: collaborationDetail
                };
            },
        }),

    })
});

export const {
    useGetPendingTicketQuery,
    useGetCollaborationTicketQuery,
    useGetCompletedTicketQuery,
    useGetInProgressTicketQuery,
    useGetTicketDetailQuery,
    useGetTicketListQuery,
    useGetOrganizatioUnitChildrenQuery,

    usePatchReceptionTicketMutation,
    usePatchCompleteTicketMutation,
    usePatchInprogressTicketMutation,
    usePatchCollaborationTicketsMutation
} = requestForm;
