import { BaseQueryFn } from '@reduxjs/toolkit/query';
import jsonp from 'jsonp';

const jsonpBaseQuery: BaseQueryFn<{ url: string; params?: Record<string, any> }, unknown, unknown> = async ({
  url,
  params
}) => {
  return new Promise((resolve) => {
    const queryString = new URLSearchParams({
      ...params
    }).toString();
    const fullUrl = `${url}?${queryString}`;

    const callbackName = 'wfappcallback'; // Default callback name

    (window as any)[callbackName] = (data: any) => {
      console.log('JSONP Response:', data);
      resolve({ data }); // Resolve the promise with the data
      delete (window as any)[callbackName]; // Clean up the callback function after use
    };

    jsonp(fullUrl, (err, data) => {
      if (err) {
        resolve({
          error: {
            status: 'FETCH_ERROR',
            data: err.message
          }
        });
      }
    });
  });
};

export default jsonpBaseQuery;
