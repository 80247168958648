import React from 'react';
import { Divider, Typography, Card, Timeline } from 'antd';
import { requestform } from 'messages';
import { TicketDetail } from 'types';
import Avatar from 'components/common/avatar/Avatar';

interface ticketCreateInformation {
  requestItem: TicketDetail | null;
}

const RequestDetailCreateFormInformation: React.FC<ticketCreateInformation> = ({ requestItem }) => {
  return (
    <div className='min-h-[937px] w-[320px] border-t-2 bg-[#f5f5f7] p-6'>
      <Typography.Text className='text-[12px]/[16px] font-semibold uppercase text-[#868D97]'>
        {requestform.createInformationTitle}
      </Typography.Text>

      <div className='mt-[20px] space-y-2'>
        <Typography.Text className='text-[12px]/[16px] text-[#6C737E]'>{requestform.acceptChannel}</Typography.Text>
        <br />
        <Typography.Text className='text-[16px]/[20px] text-[#141414]'>
          {requestItem?.channel.channelName || '-'}
        </Typography.Text>
      </div>
      <Divider className='mt-[20px] bg-[#D4D8DE]' />

      <div>
        <div className='mt-[20px] space-y-2'>
          <Typography.Text className='text-[12px]/[16px] text-[#6C737E]'>{requestform.dateRequest}</Typography.Text>
          <br />
          <Typography.Text className='text-[16px]/[20px] text-[#141414]'>
            {requestItem?.createdAt && (
              <>
                {requestItem?.createdAt.split('T')[0].split('-').reverse().join('/')},{' '}
                {requestItem?.createdAt.split('T')[1].split(':').slice(0, 2).join(':')}
              </>
            )}
          </Typography.Text>
        </div>
        <div className='mt-[20px] space-y-2'>
          <Typography.Text className='text-[12px]/[16px] text-[#6C737E]'>{requestform.ticketCreator}</Typography.Text>
          <div className='flex space-x-2'>
            <Avatar name={requestItem?.customerRequestName} size={20} />
            <Typography.Text className='text-[16px]/[20px] text-[#141414]'>
              {requestItem?.customerRequestName}
            </Typography.Text>
          </div>
        </div>
        <div className='mt-[20px] space-y-2'>
          <Typography.Text className='text-[12px]/[16px] text-[#6C737E]'>{requestform.appointmentDate}</Typography.Text>
          <br />
          <Typography.Text className='text-[16px]/[20px] text-[#141414]'>
            {requestItem?.appointmentDate
              ? requestItem?.appointmentDate.split('T')[0].split('-').reverse().join('/')
              : '-'}
          </Typography.Text>
        </div>
      </div>
      <Divider className='mt-[20px] bg-[#D4D8DE]' />

      <div>
        <Typography.Text className='text-[12px]/[16px] font-semibold uppercase text-[#868D97]'>
          {requestform.processByCMIS}
        </Typography.Text>
        <div className='mt-[20px] space-y-2'>
          <Typography.Text className='text-[12px]/[16px] text-[#6C737E]'>{requestform.receptionDate}</Typography.Text>
          <br />
          <Typography.Text className='text-[16px]/[20px] text-[#141414]'>
            {requestItem?.createdAt.split('T')[0].split('-').reverse().join('/')}
          </Typography.Text>
        </div>
        <div className='mt-[20px] space-y-2'>
          <Typography.Text className='text-[12px]/[16px] text-[#6C737E]'>{requestform.recipient}</Typography.Text>
          <div className='flex space-x-2'>
            <Avatar name={requestItem?.customerRequestName} size={20} />
            <Typography.Text className='text-[16px]/[20px] text-[#141414]'>
              {requestItem?.createdByName}
            </Typography.Text>
          </div>
        </div>
        <div className='mt-[20px] space-y-2'>
          <Typography.Text className='text-[12px]/[16px] text-[#6C737E]'> {requestform.corporateUnit}</Typography.Text>
          <br />
          <Typography.Text className='text-[16px]/[20px] text-[#141414]'>
            {requestItem?.administrativeUnit.administrativeUnitName}
          </Typography.Text>
        </div>
      </div>
      <Divider className='mt-[20px] bg-[#D4D8DE]' />

      <div>
        <Typography.Text className='text-[12px]/[16px] font-semibold uppercase text-[#868D97]'>
          {requestform.completeTitle}
        </Typography.Text>
        <div className='mt-[20px] space-y-2'>
          <Typography.Text className='text-[12px]/[16px] text-[#6C737E]'>{requestform.completedDate}</Typography.Text>
          <br />
          <Typography.Text className='text-[16px]/[20px] text-[#141414]'>
            {requestItem?.closedAt ? requestItem.closedAt : '-'}
          </Typography.Text>
        </div>
        <div className='mt-[20px] space-y-2'>
          <Typography.Text className='text-[12px]/[16px] text-[#6C737E]'>
            {requestform.completedPersion}
          </Typography.Text>
          <br />
          <Typography.Text className='text-[16px]/[20px] text-[#141414]'>
            {requestItem?.closedBy !== null ? requestItem?.closedBy : '-'}
          </Typography.Text>
        </div>
      </div>
      <Divider className='mt-[20px] bg-[#D4D8DE]' />
    </div>
  );
};

export default RequestDetailCreateFormInformation;
