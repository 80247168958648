import { AuthLayout, MainLayout } from 'layouts';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import { ROUTE } from './constants';
import { accessSystemRoutes } from './access-system.route';
import { accessManagementRoutes } from './access-management.route';
import { accessCustomerLookupRoutes } from './access-customer-lookup.route';

export const routes: RouteObject[] = [
  {
    Component: MainLayout,
    children: [
      {
        path: ROUTE.ALL,
        Component: lazy(() => import('pages/404'))
      },
      {
        path: ROUTE.HOME,
        Component: lazy(() => import('pages/Home'))
      },
      {
        path: ROUTE.PROFILE,
        Component: lazy(() => import('pages/Profile'))
      },
      ...accessSystemRoutes,
      ...accessManagementRoutes,
      ...accessCustomerLookupRoutes,
      {
        path: ROUTE.REQUEST_FORM,
        Component: lazy(() => import('pages/RequestForm'))
      },
      {
        path: ROUTE.LEADER_SHIFT,
        Component: lazy(() => import('pages/LeaderShift'))
      }
    ]
  },
  {
    Component: AuthLayout,
    children: [
      {
        path: ROUTE.LOGIN,
        Component: lazy(() => import('pages/Login'))
      },
      {
        path: ROUTE.FORGOT_PASSWORD,
        Component: lazy(() => import('pages/ForgotPassword'))
      },
      {
        path: ROUTE.VERIFY_FORGOT_PASSWORD,
        Component: lazy(() => import('pages/VerifyForgotPassword'))
      },
      {
        path: ROUTE.RESET_PASSWORD,
        Component: lazy(() => import('pages/ResetPassword'))
      }
    ]
  },
  {
    path: '/icons',
    Component: lazy(() => import('pages/Icons'))
  }
];
