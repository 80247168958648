import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ROUTE } from 'routes/constants';
import { useLogoutSoftPhoneMutation } from 'services/softphone/softphone.api';
import { setAuthenticated } from 'store/features/auth/auth.slice';
import { setAuthenticatedSoftPhone } from 'store/features/sip-auth/sip-auth.slice';
import { LogoutSoftPhoneDto } from 'types/dto/softphone/logout-softphone.dto';

export function useLogoutSoftPhone() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [logoutSoftPhone, { isLoading, error }] = useLogoutSoftPhoneMutation();

  const onLogoutSoftPhoneHandle = async (values: LogoutSoftPhoneDto) => {
    try {
      logoutSoftPhone(values)
        .unwrap()
        .then((res) => {
          dispatch(setAuthenticatedSoftPhone(false));
        })
        .catch((err) => console.error(err));
    } catch (err) {
      dispatch(setAuthenticatedSoftPhone(false));
    }
  };
  return {
    isLoading,
    error,
    onLogoutSoftPhoneHandle
  };
}
