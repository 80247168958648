import { Button, Image, Typography } from "antd";
import { CheckIcon, IncallAvatarIcon, NoticeCircleIcon, PauseIcon, PhoneFilledIcon, PhoneMuteBlackIcon, PhoneMuteIcon } from "assets";
import { customerLookupMessages } from "messages/customer-lookup.message";
import { useState } from "react"
import {hourglass, coppywriting} from "assets/images";



const CustomerLookupPhone = () => {
    const STATES = {
        DEFAULT: "Default State",
        READY: "Ready",
        INCOMING_CALL: "Incoming Call",
        IN_CALL: "In Call",
        IN_CALL_MUTE_ON: "In Call - Mute ON",
        CREATING_TICKET: "Đang tạo phiếu",
        TEMPORARILY_PAUSED: "Đang tạm ngưng",
        FAILED_LOGIN: "Failed Login",
    }
    const [currentState, setCurrentState] = useState(STATES.DEFAULT);
    const [havingChat, setHavingChat] = useState(true);
    const renderStateContent = () => {
        switch (currentState) {
            case STATES.DEFAULT:
                return havingChat === true ? (
                    <div className="'w-[400px] h-[244px] flex flex-col bg-[#FFFFFF] rounded-tl-xl">
                        <div className="m-5 flex flex-col">
                            <Typography.Text className="text-left font-semibold text-[12px]/[16px] tracking-normal text-[#868D87]">EXT #231321</Typography.Text>
                            <Typography.Text className="mt-[14px] text-left font-semibold tracking-normal text-[#000000] opacity-100">{customerLookupMessages.notReady}</Typography.Text>
                            <Typography.Text className="text-left mt-2 text-[16px]/[20px] tracking-normal text-[#141414] opacity-100">{customerLookupMessages.notReadyMessage}</Typography.Text>
                            <Button
                                style={{borderColor: '#06A77D'}} 
                                className="mt-9 w-[170px] h-9" 
                                icon={<CheckIcon/>}
                                onClick={() => setCurrentState(STATES.READY)}
                            >
                                <Typography.Text className="text-left text-[14px]/[18px] font-semibold tracking-normal text-[#06A77D]">{customerLookupMessages.ready}</Typography.Text>
                            </Button>
                        </div>
                    </div>
                ) : (
                    <div className="w-[400px] h-[980px] flex flex-col bg-[#FFFFFF] rounded-l-xl">
                        <div className="m-5 flex flex-col items-center justify-center">
                            <Typography.Text className="text-left font-semibold text-[12px]/[16px] tracking-normal text-[#868D87]">EXT #231321</Typography.Text>
                            <Typography.Text className="mt-[244px] text-center font-semibold tracking-normal text-[#000000] opacity-100">{customerLookupMessages.notReady}</Typography.Text>
                            <Typography.Text className="text-center mt-2 text-[16px]/[20px] tracking-normal text-[#141414] opacity-100">{customerLookupMessages.notReadyMessage}</Typography.Text>
                            <Button
                                style={{borderColor: '#06A77D'}} 
                                className="mt-9 w-[170px] h-9" 
                                icon={<CheckIcon/>}
                                onClick={() => setCurrentState(STATES.READY)}
                            >
                                <Typography.Text className="text-left text-[14px]/[18px] font-semibold tracking-normal text-[#06A77D]">{customerLookupMessages.ready}</Typography.Text>
                            </Button>
                        </div>
                    </div>
                );
            case STATES.READY:
                return havingChat === true ? (
                    <div className="'w-[400px] h-[244px] flex flex-col bg-[#FFFFFF] rounded-tl-xl">
                        <div className="m-5 flex flex-col">
                            <div className="flex flex-row justify-between items-center">
                                <div className="flex flex-col ">
                                    <Typography.Text className="text-left font-semibold text-[12px]/[16px] tracking-normal text-[#868D87]">EXT #231321</Typography.Text>
                                    <Typography.Text className="mt-3 text-left font-semibold tracking-normal text-[#000000] opacity-100">{customerLookupMessages.isReady}</Typography.Text>
                                </div>
                                <Image src={hourglass} className="w-[58px] h-[58px]" alt={'hourglass'}/>
                            </div>
                            <Typography.Text className="text-left mt-3 text-[16px]/[20px] tracking-normal text-[#141414] opacity-100">{customerLookupMessages.isReadyMessage}</Typography.Text>
                            <Button 
                                style={{borderColor: '#D4D8DE'}} 
                                className="mt-9 w-[170px] h-9" 
                                icon={<PauseIcon/>}
                                onClick={() => setCurrentState(STATES.TEMPORARILY_PAUSED)}
                            >
                                <Typography.Text className="text-left text-[14px]/[18px] font-semibold tracking-normal text-[#868D97]">{customerLookupMessages.pause}</Typography.Text>
                            </Button>
                        </div>
                    </div>
                ) : (
                    <div className="w-[400px] h-[980px] flex flex-col bg-[#FFFFFF] rounded-l-xl">
                        <div className="m-5 flex flex-col items-center justify-center">
                            <Typography.Text className="text-center font-semibold text-[12px]/[16px] tracking-normal text-[#868D87]">EXT #231321</Typography.Text>
                            <Image src={hourglass} className="w-[112px] h-[112px] mt-[80px]" alt={'hourglass'}/>
                            <Typography.Text className="mt-[52px] text-center font-semibold tracking-normal text-[#000000] opacity-100">{customerLookupMessages.isReady}</Typography.Text>
                            <Typography.Text className="text-center mt-2 text-[16px]/[20px] tracking-normal text-[#141414] opacity-100">{customerLookupMessages.isReadyMessage}</Typography.Text>
                            <Button 
                                style={{borderColor: '#D4D8DE'}} 
                                className="mt-9 w-[170px] h-9" 
                                icon={<PauseIcon/>}
                                onClick={() => setCurrentState(STATES.TEMPORARILY_PAUSED)}
                            >
                                <Typography.Text className="text-left text-[14px]/[18px] font-semibold tracking-normal text-[#868D97]">{customerLookupMessages.pause}</Typography.Text>
                            </Button>
                        </div>
                    </div>
                );
            case STATES.INCOMING_CALL:
                return havingChat === true ? (
                    <div className="'w-[400px] h-[244px] flex flex-col bg-[#222A41] rounded-tl-xl">
                        <div className="m-5 flex flex-col">
                            <Typography.Text className="text-left font-semibold text-[12px]/[16px] tracking-normal text-[#868D87]">EXT #231321</Typography.Text>
                            <div className="flex flex-row gap-[109px] items-center mt-10">
                                <div className="flex flex-col gap-2">
                                    <Typography.Text className="text-left font-semibold text-[24px]/[32px] tracking-normal text-[#FFFFFF] opacity-100">0934000662</Typography.Text>
                                    <Typography.Text className="text-left italic text-[12px]/[16px] tracking-normal text-[#FFFFFF] opacity-100">{customerLookupMessages.inComingCall}</Typography.Text>
                                </div>
                                <Button 
                                    style={{borderColor: 'transparent'}} 
                                    shape="circle" className="w-[56px] h-[56px] bg-[#02D8A0] bg-no-repeat bg-clip-padding opacity-100" 
                                    icon={<PhoneFilledIcon className="w-[28px] h-[28px]"/>}
                                    onClick={() => setCurrentState(STATES.IN_CALL)}
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="w-[400px] h-[980px] flex flex-col bg-[#222A41] rounded-l-xl">
                        <div className="m-5 flex flex-col items-center justify-center">
                            <Typography.Text className="text-center font-semibold text-[12px]/[16px] tracking-normal text-[#868D87]">EXT #231321</Typography.Text>
                            <Typography.Text className="mt-[244px] text-center font-semibold text-[24px]/[32px] tracking-normal text-[#FFFFFF] opacity-100">0934000662</Typography.Text>
                            <Typography.Text className="mt-2 text-center italic text-[12px]/[16px] tracking-normal text-[#FFFFFF] opacity-100">{customerLookupMessages.inComingCall}</Typography.Text>
                            <Button 
                                style={{borderColor: 'transparent'}} 
                                shape="circle" className="mt-8 w-[56px] h-[56px] bg-[#02D8A0] bg-no-repeat bg-clip-padding opacity-100" 
                                icon={<PhoneFilledIcon className="w-[28px] h-[28px]"/>}
                                onClick={() => setCurrentState(STATES.IN_CALL)}
                            />
                        </div>
                    </div>
                );
            case STATES.IN_CALL:
                return havingChat === true ? (
                    <div className="'w-[400px] h-[244px] flex flex-col bg-[#222A41] rounded-tl-xl">
                        <div className="m-5 flex flex-col gap-10">
                            <Typography.Text className="text-left font-semibold text-[12px]/[16px] tracking-normal text-[#868D87]">EXT #231321</Typography.Text>
                            <div className="flex flex-row gap-[45px] items-center">
                                <div className="flex flex-col gap-2">
                                    <Typography.Text className="text-left font-semibold text-[24px]/[32px] tracking-normal text-[#FFFFFF] opacity-100">0934000662</Typography.Text>
                                    <Typography.Text className="text-left italic text-[12px]/[16px] tracking-normal text-[#FFFFFF] opacity-100">{customerLookupMessages.isTalking}</Typography.Text>
                                </div>
                                <div className="flex flex-row gap-2">
                                    <Button 
                                        style={{borderColor: 'transparent'}} 
                                        shape="circle" 
                                        className="w-[56px] h-[56px] bg-[#D1131D] bg-no-repeat bg-clip-padding opacity-100" 
                                        icon={<PhoneFilledIcon className="w-[28px] h-[28px]"/>}
                                        onClick={() => setCurrentState(STATES.CREATING_TICKET)}
                                    />
                                    <Button 
                                        style={{borderColor: '#EBEBED'}} 
                                        shape="circle" 
                                        className="w-[56px] h-[56px] bg-[#222A41] bg-no-repeat bg-clip-padding opacity-100" 
                                        icon={<PhoneMuteIcon className="w-[28px] h-[28px]"/>}
                                        onClick={() => setCurrentState(STATES.IN_CALL_MUTE_ON)}
                                    />
                                </div>
                            </div>
                            <Typography.Text className="text-left italic text-[16px]/[20px] tracking-normal text-[#FFFFFF] opacity-70">{customerLookupMessages.customerWaiting}</Typography.Text>
                        </div>
                    </div>
                ) : (
                    <div className="w-[400px] h-[980px] flex flex-col bg-[#222A41] rounded-l-xl">
                        <div className="m-5 flex flex-col items-center justify-center">
                            <Typography.Text className="text-center font-semibold text-[12px]/[16px] tracking-normal text-[#868D87]">EXT #231321</Typography.Text>
                            <IncallAvatarIcon className="mt-[52px]"/>
                            <Typography.Text className="mt-8 text-center font-semibold text-[24px]/[32px] tracking-normal text-[#FFFFFF] opacity-100">0934000662</Typography.Text>
                            <Typography.Text className="mt-1 text-center italic text-[12px]/[16px] tracking-normal text-[#FFFFFF] opacity-100">{customerLookupMessages.isTalking}</Typography.Text>
                            <Typography.Text className="mt-4 text-center italic text-[16px]/[20px] tracking-normal text-[#FFFFFF] opacity-70">{customerLookupMessages.customerWaiting}</Typography.Text>
                            <div className="flex flex-row gap-2 mt-8">
                                <Button 
                                    style={{borderColor: 'transparent'}} 
                                    shape="circle" 
                                    className="w-[56px] h-[56px] bg-[#D1131D] bg-no-repeat bg-clip-padding opacity-100" 
                                    icon={<PhoneFilledIcon className="w-[28px] h-[28px]"/>}
                                    onClick={() => setCurrentState(STATES.CREATING_TICKET)}
                                />
                                <Button 
                                    style={{borderColor: '#EBEBED'}} 
                                    shape="circle" 
                                    className="w-[56px] h-[56px] bg-[#222A41] bg-no-repeat bg-clip-padding opacity-100" 
                                    icon={<PhoneMuteIcon className="w-[28px] h-[28px]"/>}
                                    onClick={() => setCurrentState(STATES.IN_CALL_MUTE_ON)}
                                />
                            </div>
                        </div>
                    </div>
                );
            case STATES.IN_CALL_MUTE_ON:
                return havingChat === true ? (
                    <div className="'w-[400px] h-[244px] flex flex-col bg-[#222A41] rounded-tl-xl">
                        <div className="m-5 flex flex-col gap-10">
                            <Typography.Text className="text-left font-semibold text-[12px]/[16px] tracking-normal text-[#868D87]">EXT #231321</Typography.Text>
                            <div className="flex flex-row gap-[45px] items-center">
                                <div className="flex flex-col gap-2">
                                    <Typography.Text className="text-left font-semibold text-[24px]/[32px] tracking-normal text-[#FFFFFF] opacity-100">0934000662</Typography.Text>
                                    <Typography.Text className="text-left italic text-[12px]/[16px] tracking-normal text-[#FFFFFF] opacity-100">{customerLookupMessages.isTalking}</Typography.Text>
                                </div>
                                <div className="flex flex-row gap-2">
                                    <Button 
                                        style={{borderColor: 'transparent'}} 
                                        shape="circle" 
                                        className="w-[56px] h-[56px] bg-[#D1131D] bg-no-repeat bg-clip-padding opacity-100" 
                                        icon={<PhoneFilledIcon className="w-[28px] h-[28px]"/>}
                                        onClick={() => setCurrentState(STATES.CREATING_TICKET)}
                                    />
                                    <Button 
                                        style={{borderColor: '#EBEBED'}} 
                                        shape="circle" className="w-[56px] h-[56px] bg-[#FFFFFF] bg-no-repeat bg-clip-padding opacity-100" 
                                        icon={<PhoneMuteBlackIcon className="w-[28px] h-[28px]"/>}
                                        onClick={() => setCurrentState(STATES.IN_CALL)}
                                    />
                                </div>
                            </div>
                            <Typography.Text className="text-left italic text-[16px]/[20px] tracking-normal text-[#FFFFFF] opacity-70">{customerLookupMessages.customerWaiting}</Typography.Text>
                        </div>
                    </div>
                ) : (
                    <div className="w-[400px] h-[980px] flex flex-col bg-[#222A41] rounded-l-xl">
                        <div className="m-5 flex flex-col items-center justify-center">
                            <Typography.Text className="text-center font-semibold text-[12px]/[16px] tracking-normal text-[#868D87]">EXT #231321</Typography.Text>
                            <IncallAvatarIcon className="mt-[52px]"/>
                            <Typography.Text className="mt-8 text-center font-semibold text-[24px]/[32px] tracking-normal text-[#FFFFFF] opacity-100">0934000662</Typography.Text>
                            <Typography.Text className="mt-1 text-center italic text-[12px]/[16px] tracking-normal text-[#FFFFFF] opacity-100">{customerLookupMessages.isTalking}</Typography.Text>
                            <Typography.Text className="mt-4 text-center italic text-[16px]/[20px] tracking-normal text-[#FFFFFF] opacity-70">{customerLookupMessages.customerWaiting}</Typography.Text>
                            <div className="flex flex-row gap-2 mt-8">
                                <Button 
                                    style={{borderColor: 'transparent'}} 
                                    shape="circle" 
                                    className="w-[56px] h-[56px] bg-[#D1131D] bg-no-repeat bg-clip-padding opacity-100" 
                                    icon={<PhoneFilledIcon className="w-[28px] h-[28px]"/>}
                                    onClick={() => setCurrentState(STATES.CREATING_TICKET)}
                                />
                                <Button 
                                    style={{borderColor: '#EBEBED'}} 
                                    shape="circle" 
                                    className="w-[56px] h-[56px] bg-[#FFFFFF] bg-no-repeat bg-clip-padding opacity-100" 
                                    icon={<PhoneMuteBlackIcon className="w-[28px] h-[28px]"/>}
                                    onClick={() => setCurrentState(STATES.IN_CALL)}
                                />
                            </div>
                        </div>
                    </div>
                );
            case STATES.CREATING_TICKET:
                return havingChat === true ? (
                    <div className="'w-[400px] h-[244px] flex flex-col bg-[#FFFFFF] rounded-tl-xl">
                        <div className="m-5 flex flex-col">
                            <div className="flex flex-row justify-between items-center">
                                <div className="flex flex-col ">
                                    <Typography.Text className="text-left font-semibold text-[12px]/[16px] tracking-normal text-[#868D87]">EXT #231321</Typography.Text>
                                    <Typography.Text className="mt-3 text-left font-semibold tracking-normal text-[#000000] opacity-100">{customerLookupMessages.CreatingTicket}</Typography.Text>
                                </div>
                                <Image src={coppywriting} className="w-[58px] h-[58px]" alt={'coppywriting'}/>
                            </div>
                            <Typography.Text className="text-left mt-3 text-[16px]/[20px] tracking-normal text-[#141414] opacity-100">{customerLookupMessages.isCreatingTicket}</Typography.Text>
                            <div className="flex flex-row gap-4">
                                <Button 
                                    style={{borderColor: '#D4D8DE'}} 
                                    className="mt-9 w-[170px] h-9" 
                                    icon={<PauseIcon/>}
                                    onClick={() => setCurrentState(STATES.TEMPORARILY_PAUSED)}
                                >
                                    <Typography.Text className="text-left text-[14px]/[18px] font-semibold tracking-normal text-[#868D97]">{customerLookupMessages.pause}</Typography.Text>
                                </Button>
                                <Button 
                                    style={{borderColor: '#06A77D'}} 
                                    className="mt-9 w-[170px] h-9" 
                                    icon={<CheckIcon/>}
                                    onClick={() => setCurrentState(STATES.READY)}
                                >
                                    <Typography.Text className="text-left text-[14px]/[18px] font-semibold tracking-normal text-[#06A77D]">{customerLookupMessages.ready}</Typography.Text>
                                </Button>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="w-[400px] h-[980px] flex flex-col bg-[#FFFFFF] rounded-l-xl">
                        <div className="m-5 flex flex-col items-center justify-center">
                            <Typography.Text className="text-center font-semibold text-[12px]/[16px] tracking-normal text-[#868D87]">EXT #231321</Typography.Text>
                            <Image src={coppywriting} className="w-[112px] h-[112px] mt-[80px]" alt=""></Image>
                            <Typography.Text className="mt-[52px] text-center font-semibold tracking-normal text-[#000000] opacity-100">{customerLookupMessages.CreatingTicket}</Typography.Text>
                            <Typography.Text className="text-center mt-2 text-[16px]/[20px] tracking-normal text-[#141414] opacity-100">{customerLookupMessages.isCreatingTicket}</Typography.Text>
                            <div className="flex flex-row gap-4 mt-[50px]">
                                <Button 
                                    style={{borderColor: '#D4D8DE'}} 
                                    className="mt-9 w-[170px] h-9" 
                                    icon={<PauseIcon/>}
                                    onClick={() => setCurrentState(STATES.TEMPORARILY_PAUSED)}
                                >
                                    <Typography.Text className="text-left text-[14px]/[18px] font-semibold tracking-normal text-[#868D97]">{customerLookupMessages.pause}</Typography.Text>
                                </Button>
                                <Button 
                                    style={{borderColor: '#06A77D'}} 
                                    className="mt-9 w-[170px] h-9"
                                    icon={<CheckIcon/>}
                                    onClick={() => setCurrentState(STATES.READY)}
                                >
                                    <Typography.Text className="text-left text-[14px]/[18px] font-semibold tracking-normal text-[#06A77D]">{customerLookupMessages.ready}</Typography.Text>
                                </Button>
                            </div>
                        </div>
                    </div>
                );
            case STATES.TEMPORARILY_PAUSED:
                return havingChat === true ? (
                    <div className="'w-[400px] h-[244px] flex flex-col bg-[#F7F8F9] rounded-tl-xl">
                        <div className="m-5 flex flex-col">
                            <Typography.Text className="text-left font-semibold text-[12px]/[16px] tracking-normal text-[#868D87]">EXT #231321</Typography.Text>
                            <Typography.Text className="mt-[14px] text-left font-semibold tracking-normal text-[#000000] opacity-100">{customerLookupMessages.isPause}</Typography.Text>
                            <Typography.Text className="text-left mt-2 text-[16px]/[20px] tracking-normal text-[#141414] opacity-100">{customerLookupMessages.notReadyMessage}</Typography.Text>
                            <Button 
                                style={{borderColor: '#06A77D'}} 
                                className="mt-9 w-[170px] h-9" 
                                icon={<CheckIcon/>}
                                onClick={() => setCurrentState(STATES.READY)}
                            >
                                <Typography.Text className="text-left text-[14px]/[18px] font-semibold tracking-normal text-[#06A77D]">{customerLookupMessages.ready}</Typography.Text>
                            </Button>
                        </div>
                    </div>
                ) : (
                    <div className="w-[400px] h-[980px] flex flex-col bg-[#FFFFFF] rounded-l-xl">
                        <div className="m-5 flex flex-col items-center justify-center">
                            <Typography.Text className="text-left font-semibold text-[12px]/[16px] tracking-normal text-[#868D87]">EXT #231321</Typography.Text>
                            <Typography.Text className="mt-[244px] text-center font-semibold tracking-normal text-[#000000] opacity-100">{customerLookupMessages.isPause}</Typography.Text>
                            <Typography.Text className="text-center mt-2 text-[16px]/[20px] tracking-normal text-[#141414] opacity-100">{customerLookupMessages.notReadyMessage}</Typography.Text>
                            <Button 
                                style={{borderColor: '#06A77D'}} 
                                className="mt-9 w-[170px] h-9" 
                                icon={<CheckIcon/>}
                                onClick={() => setCurrentState(STATES.READY)}
                            >
                                <Typography.Text className="text-left text-[14px]/[18px] font-semibold tracking-normal text-[#06A77D]">{customerLookupMessages.ready}</Typography.Text>
                            </Button>
                        </div>
                    </div>
                );
            case STATES.FAILED_LOGIN:
                return havingChat === true ? (
                    <div className="'w-[400px] h-[244px] flex flex-col bg-[#FCECED] rounded-tl-xl">
                        <div className="m-5 flex flex-col">
                            <NoticeCircleIcon className="w-8 h-8"/>
                            <div className="flex flex-col gap-2 mt-[25px]">
                                <Typography.Text className="text-left font-semibold text-[18px]/[22px] tracking-normal text-[#D1131D] opacity-100">{customerLookupMessages.failedLogin}</Typography.Text>
                                <Typography.Text className="text-left text-[16px]/[20px] tracking-normal text-[#141414] opacity-100">{customerLookupMessages.isFailedLogin}</Typography.Text>
                            </div>
                            <Button type="primary" className="mt-[30px] mb-[20px] w-[121px] h-[36px]">{customerLookupMessages.login}</Button>
                        </div>
                    </div>
                ) : (
                    <div className="w-[400px] h-[980px] flex flex-col bg-[#FCECED] rounded-l-xl">
                        <div className="m-5 flex flex-col justify-center items-center">
                            <Typography.Text className="text-center font-semibold text-[12px]/[16px] tracking-normal text-[#868D87]">EXT #231321</Typography.Text>
                            <NoticeCircleIcon className="w-[112px] h-[112px] mt-[96px]"/>
                            <Typography.Text className="mt-[52px] text-center font-semibold text-[18px]/[22px] tracking-normal text-[#D1131D] opacity-100">{customerLookupMessages.failedLogin}</Typography.Text>
                            <Typography.Text className="mt-2 text-center text-[16px]/[20px] tracking-normal text-[#141414] opacity-100">{customerLookupMessages.isFailedLogin}</Typography.Text>
                            <Button type="primary" className="mt-8 w-[121px] h-[36px]">{customerLookupMessages.login}</Button>
                        </div>
                    </div>
                );
            default:
                return STATES.DEFAULT
        }
      };
    return (
        <div>{renderStateContent()}</div>
    )
}

export default CustomerLookupPhone