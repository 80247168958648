import { createApi } from '@reduxjs/toolkit/query/react';
import { ResponseDto, ResponsePagingDto } from 'types';
import { TicketDto, CreateTicketDto, FindAllTicketCreatedHistoryDto } from 'types/dto/ticket';
import { FindAllTicketCompletedDto } from 'types/dto/ticket/find-all-ticket-completed.dto';
import { FindAllTicketInProgressDto } from 'types/dto/ticket/find-all-ticket-in-progress.dto';
import { mergeArrays } from 'utils';

import axiosBaseQuery from 'utils/base-api';

export const ticketsApi = createApi({
  reducerPath: 'ticketApi',
  tagTypes: ['tickets', 'ticket_detail', 'appointment_date', 'created-history', 'user-created-history'],
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    getTicketsInProgressByCustomerCode: builder.query<ResponsePagingDto<TicketDto>, FindAllTicketInProgressDto>({
      query: (params) => ({
        url: '/tickets/dtv/in_progress_by_customer_code',
        method: 'get',
        params
      }),
      providesTags: (result) =>
        result && result.data?.rows.length > 0
          ? result.data?.rows?.map(({ ticketId }) => ({
              type: 'tickets',
              id: ticketId
            }))
          : ['tickets']
    }),
    getTicketsCompletedByCustomerCode: builder.query<ResponsePagingDto<TicketDto>, FindAllTicketCompletedDto>({
      query: (params) => ({
        url: '/tickets/dtv/completed_by_customer_code',
        method: 'get',
        params
      }),
      providesTags: (result) =>
        result && result.data.rows.length
          ? result.data.rows.map(({ customerCode }) => ({
              type: 'tickets',
              id: customerCode
            }))
          : ['tickets']
    }),

    getTicketDetail: builder.query<ResponseDto<TicketDto>, number>({
      query: (ticketId) => ({
        url: `/tickets/detail/${ticketId}`,
        method: 'get'
      }),
      providesTags: (result) => (result?.data ? [{ type: 'ticket_detail', id: result.data.ticketId }] : [])
    }),

    createTicket: builder.mutation<ResponseDto<TicketDto>, CreateTicketDto>({
      query: (newTicket) => ({
        url: '/tickets/dtv/create_and_transfer_to_org_unit',
        method: 'post',
        data: newTicket
      }),
      invalidatesTags: ['tickets']
    }),

    getAppointmentDate: builder.query<ResponseDto<string>, string>({
      query: () => ({
        url: 'tickets/assumed_appointment_date',
        method: 'get'
      }),
      providesTags: (result) => (result?.data ? [{ type: 'appointment_date' }] : [])
    }),

    getCreatedHistory: builder.query<ResponsePagingDto<TicketDto>, FindAllTicketCreatedHistoryDto>({
      query: (params) => ({
        url: 'tickets/dtv/created_history',
        method: 'get',
        params
      }),
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, { arg }) => {
        if (arg.pageIndex !== 1) {
          currentCache.data.rows = mergeArrays(currentCache.data.rows, newItems.data.rows, 'ticketId');
        } else currentCache.data.rows = newItems.data.rows;
        currentCache.data.count = newItems.data.count;
      },
      forceRefetch({ currentArg, previousArg }) {
        return (
          currentArg?.keyword !== previousArg?.keyword ||
          currentArg?.pageIndex !== previousArg?.pageIndex ||
          currentArg?.customerCode !== previousArg?.customerCode ||
          currentArg?.customerRequestPhoneNumber !== previousArg?.customerRequestPhoneNumber ||
          currentArg?.fromDate !== previousArg?.fromDate ||
          currentArg?.toDate !== previousArg?.toDate
        );
      },
      providesTags: ['created-history']
    }),
    getUserCreatedHistory: builder.query<
      ResponsePagingDto<TicketDto>,
      FindAllTicketCreatedHistoryDto & { userId: number }
    >({
      query: ({ userId, ...params }) => ({
        url: `tickets/dtv/created_history/${userId}`,
        method: 'get',
        params
      }),
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, { arg }) => {
        if (arg.pageIndex !== 1) {
          currentCache.data.rows = mergeArrays(currentCache.data.rows, newItems.data.rows, 'ticketId');
        } else currentCache.data.rows = newItems.data.rows;
        currentCache.data.count = newItems.data.count;
      },
      forceRefetch({ currentArg, previousArg }) {
        return (
          currentArg?.keyword !== previousArg?.keyword ||
          currentArg?.pageIndex !== previousArg?.pageIndex ||
          currentArg?.customerCode !== previousArg?.customerCode ||
          currentArg?.customerRequestPhoneNumber !== previousArg?.customerRequestPhoneNumber ||
          currentArg?.fromDate !== previousArg?.fromDate ||
          currentArg?.toDate !== previousArg?.toDate
        );
      },
      providesTags: ['user-created-history']
    })
  })
});

export const {
  useGetTicketsInProgressByCustomerCodeQuery,
  useGetTicketsCompletedByCustomerCodeQuery,
  useCreateTicketMutation,
  useGetTicketDetailQuery,
  useGetAppointmentDateQuery,
  useLazyGetCreatedHistoryQuery,
  useGetCreatedHistoryQuery,
  useLazyGetUserCreatedHistoryQuery,
  useGetUserCreatedHistoryQuery
} = ticketsApi;
