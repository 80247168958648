export const ticketDetailedMessages = {
    customerCode: 'Mã khách hàng',
    vipCustomer: 'Khách hàng VIP',
    customerInformation: 'Thông tin khách hàng',
    fullName: 'Họ tên KH',
    customerPhoneNumber: 'Điện thoại KH',
    customerAddress: 'Địa chỉ KH',
    requesterInformation: 'Thông tin người yêu cầu',
    requesterFullName: 'Họ tên người Y/C',
    requesterPhoneNumber: 'Điện thoại người Y/C',
    numberOfCalls: 'Số lần gọi',
    requesterAddress: 'Địa chỉ người Y/C',
    citizenIdNumber: 'Số CCCD',
    issueDate: 'Ngày cấp',
    placeOfIssue: 'Nơi cấp',
    requestTicket: 'Phiếu yêu cầu',
    questionsAndAnswers: 'Hỏi đáp',
    notARepeatedRequest: 'Không phải yêu cầu nhiều lần',
    ticketState: 'Trạng thái phiếu',
    requestDate: 'Ngày yêu cầu',
    receptionDate: 'Ngày tiếp nhận',
    completionDate: 'Ngày hoàn tất',
    creator: 'Người tạo',
    recipient: 'Người tiếp nhận',
    completer: 'Người hoàn tất',
    appointmentDate: 'Ngày hẹn',
    unit: 'Đơn vị',
    receivingChannel: 'Kênh tiếp nhận',
    attachedFile: 'File đính kèm',
    requestContent: 'Nội dung yêu cầu',
    processingProcess: 'Quá trình xử lý',
    viewAllProcessingHistory: 'Xem tất cả lịch sử xử lý',
    completed: 'Hoàn tất',
    buttonShowAllHistoryProcess: "Xem tất cả lịch sử xử lý"
}
