import { Col, Row, Skeleton, Typography } from 'antd';
import { ReactNode } from 'react';
import InfiniteScroll from '../InfiniteScroll';
import Search from '../InputSearch';
import { FilterPopoverOptionItem } from './Filter';
import { messages } from 'messages';

type FilterSearchBoxProps = {
  onSearch?: (value: string) => void;
  isLoading?: boolean;
  loadMore?: () => void;
  options: FilterPopoverOptionItem[];
  renderItem: (item: FilterPopoverOptionItem, index: number) => ReactNode;
  hasMore: boolean;
  placeholder?: string;
  selectedCount?: any;
};
const FilterSearchBox: React.FC<FilterSearchBoxProps> = ({
  onSearch,
  isLoading,
  hasMore,
  loadMore,
  options,
  renderItem,
  placeholder,
  selectedCount
}) => {
  return (
    <div className='w-full'>
      {onSearch && (
        <Search
          className='mb-2'
          onChange={(searchString) => {
            onSearch(searchString);
          }}
          placeholder={placeholder}
        />
      )}
      {/* <div className='mb-4 flex min-h-5 items-center gap-1'>
        <Typography.Text type='secondary' className='text-sm'>
          {messages.selectedCount}
        </Typography.Text>{' '}
        <Typography.Text>{selectedCount}</Typography.Text>
      </div> */}
      <div className='no-scrollbar -mx-4 max-h-[300px] w-[calc(100%+32px)] overflow-auto'>
        <Row align='middle'>
          <InfiniteScroll
            className='flex-1'
            isLoading={isLoading}
            next={() => loadMore?.()}
            hasMore={Boolean(hasMore)}
            loader={
              <Skeleton
                active
                title={false}
                paragraph={{
                  rows: 3
                }}
              />
            }
            endMessage={<p></p>}
          >
            {options.map((o, idx) => (
              <Col
                span={24}
                key={o.value?.toString() || `key-${idx}`}
                className='flex h-11 items-center px-4 hover:bg-hoverColor1'
              >
                {renderItem(o, idx)}
              </Col>
            ))}
          </InfiniteScroll>
        </Row>
      </div>
    </div>
  );
};
export default FilterSearchBox;
