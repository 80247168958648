import { useDispatch, useSelector } from 'react-redux';
import { useGetSoftPhoneTokenMutation } from 'services';
import { RootState } from 'store';
import { setAuthenticatedSoftPhone, setSoftPhoneToken } from 'store/features/sip-auth/sip-auth.slice';
import { GetWorldPhoneTokenDto } from 'types';

export function useGetSoftPhoneToken() {
  const dispatch = useDispatch();

  const [getWorldPhoneToken, { isLoading, error }] = useGetSoftPhoneTokenMutation();

  const onGetWorldPhoneToken = async (values: GetWorldPhoneTokenDto) => {
    try {
      getWorldPhoneToken(values)
        .unwrap()
        .then((res:any) => {
          dispatch(setAuthenticatedSoftPhone(true));
          dispatch(setSoftPhoneToken(res.data?.token));
        })
        .catch((err:any) => console.error(err));
    } catch (err) {
      console.log(err);
    }
  };
  return {
    isLoading,
    error,
    onGetWorldPhoneToken,
  };
}
