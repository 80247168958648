import { usePaging } from 'hooks/usePaging';
import { useGetCreatedHistoryQuery } from 'services/tickets';
import { FindAllTicketCreatedHistoryDto } from 'types/dto/ticket';
import { DEFAULT_PAGE_SIZE } from 'utils';
const pageSize = DEFAULT_PAGE_SIZE;
export function useTicketCreatedHistoryPaging(params: Omit<FindAllTicketCreatedHistoryDto, 'pageSize'>) {
  const { keyword, customerCode, customerRequestPhoneNumber, fromDate, toDate } = params;
  const { pageIndex, changePage, resetPage } = usePaging(params);
  const {
    data: ticketResponse,
    error,
    isFetching,
    isLoading
  } = useGetCreatedHistoryQuery(
    {
      pageIndex,
      pageSize,
      keyword,
      customerCode,
      customerRequestPhoneNumber,
      fromDate,
      toDate
    },
    {
      refetchOnMountOrArgChange: true
    }
  );
  const handleChangePage = changePage;

  return {
    ticketCreatedHistories: ticketResponse?.data.rows,
    count: ticketResponse ? ticketResponse.data.count : 0,
    isLoading: isFetching || isLoading,
    pageIndex,
    handleChangePage,
    resetPage,
    error
  };
}
