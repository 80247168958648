import { useGetCollaborationTicketQuery, useGetCompletedTicketQuery, useGetInProgressTicketQuery, useGetOrganizatioUnitChildrenQuery, useGetPendingTicketQuery, useGetTicketDetailQuery, useGetTicketListQuery, usePatchReceptionTicketMutation } from "services";
import { RequestData } from "types";


// GET Tickets based on statuss
export function GetPendingTicket(pageSize: number) {
    const { data: customerRes, isLoading, refetch } = useGetPendingTicketQuery(pageSize!, {
        skip: !pageSize,
        refetchOnMountOrArgChange: true
    });

    return {
        pending: customerRes?.data.rows || [],
        pendingCount: Number(customerRes?.data.count),
        refetch: refetch ?? (() => { }),
        isLoading
    };
}
export function GetInProgressTicket(pageSize: number) {
    const { data: customerRes, isLoading, refetch } = useGetInProgressTicketQuery(pageSize!, {
        skip: !pageSize,
        refetchOnMountOrArgChange: true
    });

    return {
        inprogress: customerRes?.data.rows || [],
        inprogressCount: Number(customerRes?.data.count),
        refetch: refetch ?? (() => { }),
        isLoading
    };
}
export function GetCompletedTicket(pageSize: number) {
    const { data: customerRes, isLoading, refetch } = useGetCompletedTicketQuery(pageSize!, {
        skip: !pageSize,
        refetchOnMountOrArgChange: true
    });

    return {
        completed: customerRes?.data.rows || [],
        completedCount: Number(customerRes?.data.count),
        refetch: refetch ?? (() => { }),
        isLoading
    };
}
export function GetCollaborationTicket(pageSize: number) {
    const { data: customerRes, isLoading, refetch } = useGetCollaborationTicketQuery(pageSize!, {
        skip: !pageSize,
        refetchOnMountOrArgChange: true
    });

    return {
        collaboration: customerRes?.data.rows || [],
        collaborationCount: Number(customerRes?.data.count),
        refetch: refetch ?? (() => { }),
        isLoading
    };
}

//Ticket Detail
export function GetTicketDetail(ticketId: number) {
    const { data: customerRes, isLoading } = useGetTicketDetailQuery(ticketId, {
        skip: !ticketId,
        refetchOnMountOrArgChange: true
    });

    return {
        requestDetail: customerRes?.data,
        isLoading
    };
}


// GET Tickets with conditional 
export function GetTicketList(requestData: RequestData) {
    const { data: customerRes, isLoading, refetch } = useGetTicketListQuery(requestData!, {
        skip: !requestData,
        refetchOnMountOrArgChange: true
    });

    return {
        data: customerRes?.data.rows || [],
        dataCount: Number(customerRes?.data.count),
        refetch: refetch ?? (() => { }),
        isLoading
    };
}

export function GetOrganizationUnitChildren(organizationUnitId: number) {
    const { data: organizationUnitChildrenList, isLoading } = useGetOrganizatioUnitChildrenQuery(organizationUnitId!, {
        skip: !organizationUnitId,
        refetchOnMountOrArgChange: true
    });

    return {
        data: organizationUnitChildrenList?.data.rows || [],
        isLoading
    };
}