import { Form, List, Typography } from 'antd';
import { useSearchParamsForm, useServiceTypesPaging } from 'hooks';
import React, { useEffect, useRef, useState } from 'react'
import { FindWithKeywordDto } from 'types';
import { InputSearch } from 'components/common';
import { serviceTypesMessages } from 'messages';
import { CustomerLookupTableArrowRight } from 'assets';

type FindType = FindWithKeywordDto;
type ServiceTypeListProps = {
    onSelectServiceType: (serviceTypeId: number) => void;
}

const ServiceTypeList: React.FC<ServiceTypeListProps> = ({ onSelectServiceType }) => {
    const {setSearchParams, getSearchParams} = useSearchParamsForm();
    
    const [form] = Form.useForm<FindType>();
    const values = Form.useWatch([], form) || getSearchParams();
    const {serviceTypes, isLoading } = useServiceTypesPaging({...values})

    return (
        <div className='flex flex-col'>
            <Form
                scrollToFirstError={{ behavior: 'smooth', block: 'start' }}
                form={form}
                initialValues={getSearchParams()}
                onValuesChange={() => {
                    const values = form.getFieldsValue();
                    setSearchParams(values);
                }}
            >
                <Form.Item<FindType> className='max-w-[520px]' name='keyword'>
                    <InputSearch className='bg-[#FFFFFF] rounded-lg mt-10 border' placeholder={serviceTypesMessages.name}/>
                </Form.Item>
            </Form>
            <List 
                className='mt-6 mb-4'
                itemLayout='vertical'
                loading={isLoading}
                size='large'
                dataSource={serviceTypes || []}
                renderItem={(item) => (
                    <List.Item
                        className='hover:bg-slate-200 cursor-pointer'
                        key={item.serviceTypeId}
                        onClick={() => onSelectServiceType(item.serviceTypeId)}
                    >
                        <div className='flex flex-row items-center justify-between'>
                            <Typography.Text className='text-left text-[16px]/[20px] text-[#181818] tracking-normal opacity-100 w-[456px]'>{item.name}</Typography.Text>
                            <CustomerLookupTableArrowRight width={7} height={14} />
                        </div>
                    </List.Item>
                )}
            >

            </List>
        </div>
    )
}

export default ServiceTypeList