import { useGetCustomerCountQuery } from "services";
import { FindAllCustomerDto } from "types";

export function useCustomersCount(params: Omit<FindAllCustomerDto, 'pageIndex' | 'pageSize'>) {
    const {
        customerName,
        customerAddress,
        meterSerialNumber,
        customerCode,
        routeCode,
        meterPointCode,
        organizationUnitCode,
        wardId,
        districtId,
    } = params;

    const queryParams = {
        customerName,
        customerAddress,
        meterSerialNumber,
        customerCode,
        routeCode,
        meterPointCode,
        organizationUnitCode,
        wardId,
        districtId,
    };

    const { data: count } = useGetCustomerCountQuery(queryParams);

    return {
        count: count?.data || 0,
    };
}