import { Typography, Card, Button, Avatar as AntdAvatar, Modal, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { InprogressTickets, TicketDetail } from 'types';

import { requestform } from 'messages';
import { BlueArrow, GreenCheck, HourGlassStatusIcon, PrinterIcon } from 'assets';
import { GetTicketDetail } from 'hooks/request-form';
import RequestDetailCreateFormInformation from './RequestDetailCreateFormInformation';
import RequestDetailMainInformation from './RequestDetailMainInformation';
import TextArea from 'antd/es/input/TextArea';
import { ticketDetailedMessages } from 'messages/ticket-detailed.messages';
import Avatar from 'components/common/avatar/Avatar';

import progressIcon from '../../assets/svg/progressing-setting.svg';
import { DownOutlined } from '@ant-design/icons';
import { usePatchCompleteTicketMutation, usePatchInprogressTicketMutation } from 'services';
import ModalTransferCmis from './ModalTransferCmis';
import ModalTransferUnit from './ModalTransferUnit';

interface ticketFromDetail {
  status?: string;
  ticketId: number;
  cancelDetailModal: () => void;
}

const RequestDetail: React.FC<ticketFromDetail> = ({ status, ticketId, cancelDetailModal }) => {
  const { requestDetail } = GetTicketDetail(ticketId);
  const [isShow, setIsShow] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [isOpenTransferModal, setIsOpenTransferModal] = useState(false);
  const [processingContent, setProcessingContent] = useState<string>('');

  const [requestItem, setRequestItem] = useState<TicketDetail | null>(null);

  // Control dispatch API
  const [completeTicket] = usePatchCompleteTicketMutation();
  const [inprocessTicket] = usePatchInprogressTicketMutation();

  useEffect(() => {
    if (requestDetail) {
      const formattedRequestDetail: TicketDetail = Object.assign({}, defaultTicketDetail, requestDetail);
      setRequestItem(formattedRequestDetail);
    }
  }, [requestDetail]);

  // Control Modal Open / Close
  const setShowAll = () => {
    setIsShow(true);
  };
  const openModalCMIS = () => {
    setIsModal(true);
  };
  const closeModalCMIS = () => {
    setIsModal(false);
    cancelDetailModal();
  };
  const openTransferModal = () => {
    setIsOpenTransferModal(true);
  };
  const closeTransferModal = () => {
    setIsOpenTransferModal(false);
  };

  const completeTicketAction = () => {
    if (ticketId) {
      completeTicket(ticketId)
        .unwrap()
        .then((res) => {
          message.success('Hoàn thành phiếu yêu cầu', 2);
          cancelDetailModal();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const inprogressTicketAction = () => {
    const data: InprogressTickets = {
      ticketId,
      processingContent
    };
    if (ticketId) {
      inprocessTicket(data)
        .unwrap()
        .then((res) => {
          message.success('Cập nhật phiếu thành công', 2);
          cancelDetailModal();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <Card
      title={
        <Typography.Text className='w-full text-center text-[16px]/[22px] font-semibold'>
          {requestform.requestTicket}
          {ticketId}
        </Typography.Text>
      }
      className='request-form-detail-card min-h-[1000px] text-center'
    >
      <div className='flex w-full text-left'>
        {/* Left ticket */}
        <RequestDetailCreateFormInformation requestItem={requestItem} />

        {/* Right Ticket */}
        <section className='flex flex-col justify-between'>
          <section className='min-w-[768px] p-6'>
            <div className='flex items-center justify-between'>
              <Typography.Text className='text-[26px]/[34px] font-bold text-[#141414]'>
                {requestItem?.serviceType.name}
              </Typography.Text>
              <div className='flex items-center space-x-2'>
                <PrinterIcon />
                <Typography.Text className='text-[16px]/[20px] text-[#1564E8]'>
                  {requestform.printTicket}
                </Typography.Text>
              </div>
            </div>

            <div>
              {requestItem?.isRequestMultipleTime ? (
                <Typography.Text className='text-[14px]/[18px] text-[#D1131D]'>
                  {requestform.multiplleTimeRequest}
                </Typography.Text>
              ) : (
                <Typography.Text className='text-[14px]/[18px] text-[#06A77D]'>
                  {requestform.notMultiplleTimeRequest}
                </Typography.Text>
              )}
            </div>

            {/* Status bar*/}
            {status && (
              <div className='pb-6'>
                <div
                  className={`mt-3 flex justify-between rounded-[5px] px-4 py-[11px] ${
                    status === 'pending-reception'
                      ? 'bg-[#FF9500]'
                      : status === 'in-progress'
                        ? 'bg-[#1564E8]'
                        : status === 'collaboration'
                          ? 'bg-[#AF52DE]'
                          : status === 'completed'
                            ? 'bg-[#02D8A0]'
                            : status === 'obstacle'
                              ? 'bg-[#D1131D]'
                              : 'bg-gray-500'
                  }`}
                >
                  <div>
                    <Typography.Text className='text-[14px]/[18px] font-semibold text-[#FFFFFF]'>
                      {requestform.detailStatusRequestForm}
                    </Typography.Text>{' '}
                    <Typography.Text className='text-[14px]/[18px] text-[#FFFFFF]'>
                      {status === 'pending-reception'
                        ? 'Chờ tiếp nhận'
                        : status === 'in-progress'
                          ? 'Đang xử lý'
                          : status === 'collaboration'
                            ? 'Phối hợp xử lý'
                            : status === 'completed'
                              ? 'Đã xử lý'
                              : status === 'obstacle'
                                ? 'Trở ngại'
                                : 'bg-gray-500'}
                    </Typography.Text>
                  </div>
                  <HourGlassStatusIcon />
                </div>

                {/* CMIS Transfer */}
                {status === 'pending-reception' ? (
                  <div className='mt-1 justify-between rounded-[5px] bg-[#EBEBED] px-4 py-[11px]'>
                    <Typography.Text className='text-[14px]/[18px] font-semibold text-[#141414]'>
                      {requestform.isTranferCmisTicket}
                    </Typography.Text>
                    <Typography.Text className='text-[14px]/[18px] text-[#141414]'>
                      {requestform.typeCmisTicket}
                    </Typography.Text>{' '}
                    <DownOutlined className='h-2 w-[10px]' />
                  </div>
                ) : requestItem?.cmisRequestId == null ? (
                  <div className='mt-1 flex justify-between rounded-[5px] border border-[#D1131D] bg-[#06A77D00] px-4 py-[11px]'>
                    <div>
                      <Typography.Text className='text-[14px]/[18px] font-semibold text-[#D1131D]'>
                        {requestform.cmisTransferFail}
                      </Typography.Text>{' '}
                      <Typography.Text className='text-[14px]/[18px] text-[#141414]'>
                        {requestform.typeCmisTicket}
                      </Typography.Text>{' '}
                      <DownOutlined className='h-2 w-[10px]' />
                    </div>
                    <button>
                      <Typography.Text className='text-[14px]/[18px] text-[#1564E8]'>
                        {requestform.tryAgainButton}
                      </Typography.Text>
                    </button>
                  </div>
                ) : (
                  <div className='mt-1 flex justify-between rounded-[5px] bg-[#06A77D00] py-[11px]'>
                    <div className='flex space-x-1'>
                      <GreenCheck />
                      <Typography.Text className='text-[14px]/[18px] italic text-[#141414]'>
                        {requestform.cmisTranfered}
                        {requestItem.cmisRequestId}
                      </Typography.Text>
                    </div>{' '}
                    <Typography.Text className='text-[14px]/[18px] italic text-[#868D97]'>
                      {/* This is static data, add dynamic data latter */}
                      Lúc 16:32, 27/12/2024
                    </Typography.Text>
                  </div>
                )}
              </div>
            )}
            <div
              className={`${status === 'completed' || status === 'collaboration' ? 'h-[688px] overflow-y-auto' : 'max-h-[600px] overflow-y-auto'}`}
            >
              <RequestDetailMainInformation requestItem={requestItem} />

              {/* Processing */}
              <div>
                <div className='flex items-center space-x-3'>
                  <img src={progressIcon} alt='' />
                  <Typography.Text className='text-[18px]/[22px] font-semibold text-[#141414]'>
                    {requestform.processingTitle}
                  </Typography.Text>
                </div>

                {/* Timeline */}
                <div className='mt-[20px]'>
                  {requestItem?.status.code === 'DANGXULY' ? (
                    <>
                      <TextArea
                        placeholder='Nội dung phản hồi'
                        autoSize={{ minRows: 5, maxRows: 5 }}
                        className='rounded-lg bg-[#F7F8F6]'
                        value={processingContent}
                        onChange={(e) => setProcessingContent(e.target.value)}
                      />
                    </>
                  ) : (
                    ''
                  )}

                  {requestItem && requestItem?.ticketEvents.length > 0 ? (
                    <>
                      {isShow ? (
                        requestItem?.ticketEvents.map((event, index) => (
                          <>
                            <div className='relative mx-4 flex items-center justify-between'>
                              <div className={`absolute -left-4 top-[60%] rounded-full border bg-white p-2`}>
                                <BlueArrow />
                              </div>
                              <div className={`flex w-full items-center justify-between border-l pt-16`}>
                                <div className='mx-6 w-full'>
                                  <Typography.Text className='text-[16px]/[18px] font-semibold text-[#141414]'>
                                    {event.createdByName}
                                  </Typography.Text>{' '}
                                  <Typography.Text> {event.actionType.name}</Typography.Text>
                                  <br />
                                  {(() => {
                                    const dateObj = new Date(event.createdAt);

                                    const hh = dateObj.getHours().toString().padStart(2, '0');
                                    const mm = dateObj.getMinutes().toString().padStart(2, '0');

                                    const dd = dateObj.getDate().toString().padStart(2, '0');
                                    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
                                    const yyyy = dateObj.getFullYear();
                                    const formattedDate = `${dd} tháng ${month}, ${yyyy}`;

                                    return (
                                      <div className='text-[14px] italic text-[#141414]'>
                                        {hh}:{mm}{' '}
                                        <Typography.Text className='relative bottom-1 text-2xl'>.</Typography.Text>{' '}
                                        {formattedDate}{' '}
                                        <Typography.Text className='relative bottom-1 text-2xl'>.</Typography.Text>{' '}
                                        {event.organizationUnitName}
                                      </div>
                                    );
                                  })()}
                                </div>
                                <Avatar name={requestItem?.ticketEvents[index].createdByName} size={32} />
                              </div>
                            </div>
                          </>
                        ))
                      ) : (
                        <div className='relative mx-4 flex items-center justify-between'>
                          <div
                            className={`absolute -left-4 ${
                              requestItem?.status.code === 'CHOTIEPNHAN' ? 'top-[20%]' : ''
                            } rounded-full border bg-white p-2`}
                          >
                            <BlueArrow />
                          </div>
                          <div
                            className={`flex w-full items-center justify-between border-l ${
                              requestItem?.status.code === 'CHOTIEPNHAN'
                                ? ''
                                : requestItem?.ticketEvents.length >= 2
                                  ? 'py-8'
                                  : 'pt-16'
                            }`}
                          >
                            <div className='mx-6 w-full'>
                              <Typography.Text className='text-[16px]/[18px] font-semibold text-[#141414]'>
                                {requestItem?.ticketEvents[0].createdByName}
                              </Typography.Text>{' '}
                              <Typography.Text> {requestItem?.ticketEvents[0].actionType.name}</Typography.Text>
                              <br />
                              {(() => {
                                const dateObj = new Date(requestItem?.ticketEvents[0].createdAt);

                                const hh = dateObj.getHours().toString().padStart(2, '0');
                                const mm = dateObj.getMinutes().toString().padStart(2, '0');

                                const dd = dateObj.getDate().toString().padStart(2, '0');
                                const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
                                const yyyy = dateObj.getFullYear();
                                const formattedDate = `${dd} tháng ${month}, ${yyyy}`;

                                return (
                                  <div className='text-[14px] italic text-[#141414]'>
                                    {hh}:{mm}{' '}
                                    <Typography.Text className='relative bottom-1 text-2xl'>.</Typography.Text>{' '}
                                    {formattedDate}{' '}
                                    <Typography.Text className='relative bottom-1 text-2xl'>.</Typography.Text>{' '}
                                    {requestItem?.ticketEvents[0].organizationUnitName}
                                  </div>
                                );
                              })()}
                            </div>
                            <Avatar name={requestItem?.ticketEvents[0].createdByName} size={32} />
                          </div>
                        </div>
                      )}

                      <div className='relative flex items-center'>
                        {requestItem?.ticketEvents.length >= 2 ? (
                          <>
                            {isShow ? (
                              ''
                            ) : (
                              <div className={`relative flex items-center`}>
                                <div>
                                  <AntdAvatar.Group>
                                    {requestItem?.ticketEvents
                                      .slice(1, requestItem?.ticketEvents.length)
                                      .map((avt, index) => (
                                        <AntdAvatar
                                          key={index}
                                          src={<Avatar name={requestItem?.createdByName} size={32} />}
                                          size={32}
                                          alt={`Avatar ${index + 1}`}
                                          className={`rounded-full border-2 border-white ${index !== 0 ? '-ml-5' : ''}`}
                                        />
                                      ))}
                                  </AntdAvatar.Group>
                                </div>

                                <Button
                                  className='text border-none bg-transparent text-[#1564E8] shadow-none'
                                  onClick={setShowAll}
                                >
                                  {ticketDetailedMessages.buttonShowAllHistoryProcess}
                                </Button>
                              </div>
                            )}
                          </>
                        ) : (
                          ''
                        )}
                      </div>
                    </>
                  ) : (
                    ' '
                  )}
                </div>
              </div>
            </div>
          </section>

          {/* Exchange Button */}
          {status !== 'completed' && (
            <div className='flex h-[88px] items-center justify-end border-t-2 p-6 text-left'>
              {status === 'pending-reception' && (
                <button
                  onClick={openModalCMIS}
                  className='rounded-lg bg-[#1564E8] px-6 py-[10px] text-[16px]/[20px] font-semibold text-[#FFFFFF]'
                >
                  {requestform.acceptTicket}
                </button>
              )}

              {status === 'in-progress' && (
                <div className='flex space-x-4'>
                  <button
                    onClick={() => {}}
                    className='rounded-lg border border-[#D1131D] px-6 py-[10px] text-[16px]/[20px] font-semibold text-[#D1131D]'
                  >
                    {requestform.obstacleTickets}
                  </button>
                  <button className='rounded-lg border border-[#D1131D] bg-transparent px-6 py-[10px] text-[16px]/[20px] font-semibold text-[#D1131D]'>
                    {requestform.returnCCCorWrongTicket}
                  </button>
                  <button
                    onClick={openTransferModal}
                    className='rounded-lg border border-[#1564E8] bg-transparent px-6 py-[10px] text-[16px]/[20px] font-semibold text-[#1564E8]'
                  >
                    {requestform.transferCollaboration}
                  </button>
                  <button
                    onClick={inprogressTicketAction}
                    className='rounded-lg bg-[#FF9500] px-6 py-[10px] text-[16px]/[20px] font-semibold text-[#FFFFFF]'
                  >
                    {requestform.inprocessTicket}
                  </button>
                  <button
                    onClick={completeTicketAction}
                    className='rounded-lg bg-[#28A745] px-6 py-[10px] text-[16px]/[20px] font-semibold text-[#FFFFFF]'
                  >
                    {requestform.completedButton}
                  </button>
                </div>
              )}

              {status === 'collaboration' && (
                <div className='flex space-x-4'>
                  <button className='rounded-lg border border-[#D1131D] bg-transparent px-6 py-[10px] text-[16px]/[20px] font-semibold text-[#D1131D]'>
                    {requestform.returnCCCorWrongTicket}
                  </button>
                  <button
                    onClick={completeTicketAction}
                    className='rounded-lg bg-[#28A745] px-6 py-[10px] text-[16px]/[20px] font-semibold text-[#FFFFFF]'
                  >
                    {requestform.completedButton}
                  </button>
                </div>
              )}
            </div>
          )}
        </section>
      </div>

      <Modal
        centered
        className='ant-modal-body-p-none min-w-[568px] rounded-xl bg-[#F5F4F6]'
        open={isModal}
        onCancel={closeModalCMIS}
        footer={null}
        destroyOnClose
      >
        <ModalTransferCmis requestItem={requestItem} closeModalCMIS={closeModalCMIS} />
      </Modal>

      <Modal
        centered
        className='ant-modal-body-p-none min-w-[568px] rounded-xl bg-[#F5F4F6]'
        open={isOpenTransferModal}
        onCancel={closeTransferModal}
        footer={null}
        destroyOnClose
      >
        {/* <ModalObstacleRequest /> */}
        <ModalTransferUnit
          requestItem={requestItem}
          processingContent={processingContent}
          cancelDetailModal={cancelDetailModal}
        />
      </Modal>
    </Card>
  );
};

export default RequestDetail;

const defaultTicketDetail: TicketDetail = {
  ticketId: 0,
  customerCode: '',
  organizationUnitCode: '',
  organizationUnit: {
    organizationUnitId: 0,
    code: '',
    name: '',
    shortName: ''
  },
  customerRequestName: '',
  customerRequestPhoneNumber: '',
  customerRequestAddress: '',
  customerRequestCicNumber: '',
  customerRequestCicIssueDate: '',
  customerRequestCicIssuePlace: '',
  customerRequestCicType: 0,
  numOfRequests: 0,
  content: '',
  isPriority: false,
  isDoubleCheck: false,
  isRequestMultipleTime: false,
  isNotOnTime: false,
  channelId: 0,
  channel: {
    channelId: 0,
    channelCode: '',
    channelName: ''
  },
  serviceTypeId: 0,
  serviceType: {
    serviceTypeId: 0,
    code: '',
    name: '',
    childrenCompact: [],
    parentCompact: ''
  },
  administrativeUnitId: 0,
  administrativeUnit: {
    administrativeUnitId: 0,
    administrativeUnitCode: '',
    administrativeUnitName: ''
  },
  cmisRequestId: '',
  appointmentDate: '',
  statusId: 0,
  status: {
    optionId: 0,
    code: '',
    name: ''
  },
  latitude: 0,
  longitude: 0,
  createdBy: 0,
  createdByName: '',
  createdAt: '',
  createdAtDateOnly: '',
  updatedAt: '',
  closedAt: '',
  closedAtDateOnly: '',
  closedBy: 0,
  ticketEvents: [],
  files: [],
  additionalInfo: {
    ticketId: 0,
    phaseNumber: 0,
    usagePurpose: '',
    outageAt: '',
    outageReasonId: 0,
    powerRestorationAt: '',
    isOutageEntireArea: false,
    isNotRequestToSendSms: false,
    incidenAddress: '',
    incidentAdministrativeUnitId: 0,
    createdAt: '',
    createdBy: 0,
    createdByName: ''
  }
};
