import { usePaging } from "hooks/usePaging";
import { useGetCustomerQuery } from "services";
import { FindAllCustomerDto } from "types";
import { DEFAULT_PAGE_SIZE } from "utils";

const pageSize = 7;
export function useCustomerPaging(params: Omit<FindAllCustomerDto, 'pageIndex' | 'pageSize'>) {
    const {
        customerName,
        customerAddress,
        meterSerialNumber,
        customerCode,
        routeCode,
        meterPointCode,
        organizationUnitCode,
        wardId,
        districtId,
    } = params;

    const { pageIndex, changePage, resetPage } = usePaging(params);

    // Kiểm tra nếu có giá trị tìm kiếm nào được nhập thì gọi API
    const shouldFetch = !!(
        customerName ||
        customerAddress ||
        meterSerialNumber ||
        customerCode ||
        routeCode ||
        meterPointCode ||
        organizationUnitCode ||
        wardId ||
        districtId
    );

    const queryParams = shouldFetch
        ? {
              pageIndex,
              pageSize,
              customerName,
              customerAddress,
              meterSerialNumber,
              customerCode,
              routeCode,
              meterPointCode,
              organizationUnitCode,
              wardId,
              districtId,
          }
        : {
              pageIndex,
              pageSize,
              customerName: '',
              customerAddress: '',
              meterSerialNumber: '',
              customerCode: '',
              routeCode: '',
              meterPointCode: '',
              organizationUnitCode: '',
              wardId: undefined,
              districtId: undefined,
          };

    const {
        data: customersResponse,
        error,
        isFetching,
        isLoading,
    } = useGetCustomerQuery(queryParams, {
        skip: !shouldFetch, // Chặn gọi API khi không có giá trị tìm kiếm
    });

    const handleChangePage = changePage;

    return {
        customers: shouldFetch ? customersResponse?.data.rows || [] : [],
        count: shouldFetch ? customersResponse?.data.count || 0 : 0,
        isLoading: shouldFetch && (isFetching || isLoading),
        pageIndex,
        handleChangePage,
        resetPage,
        error,
    };
}
