import { usePaging } from 'hooks/usePaging';
import { useGetUserCreatedHistoryQuery } from 'services/tickets';
import { FindAllTicketCreatedHistoryDto } from 'types/dto/ticket';
import { DEFAULT_PAGE_SIZE } from 'utils';
const pageSize = DEFAULT_PAGE_SIZE;
export function useTicketUserCreatedHistoryPaging(
  params: Omit<FindAllTicketCreatedHistoryDto & { userId: number }, 'pageSize'>
) {
  const { keyword, customerCode, customerRequestPhoneNumber, fromDate, toDate, userId } = params;
  const { pageIndex, changePage, resetPage } = usePaging(params);
  const {
    data: ticketResponse,
    error,
    isFetching,
    isLoading
  } = useGetUserCreatedHistoryQuery(
    {
      pageIndex,
      pageSize,
      keyword,
      customerCode,
      customerRequestPhoneNumber,
      fromDate,
      toDate,
      userId
    },
    {
      refetchOnMountOrArgChange: true
    }
  );
  const handleChangePage = changePage;

  return {
    ticketCreatedHistories: ticketResponse?.data.rows,
    count: ticketResponse ? ticketResponse.data.count : 0,
    isLoading: isFetching || isLoading,
    pageIndex,
    handleChangePage,
    resetPage,
    error
  };
}
