import { usePaging } from "hooks/usePaging";
import { useGetTicketsInProgressByCustomerCodeQuery } from "services/tickets";
import { FindAllTicketInProgressDto } from "types/dto/ticket/find-all-ticket-in-progress.dto";

const pageSize = 3;
export function useTicketsInProgressPaging(params: Omit<FindAllTicketInProgressDto, 'pageIndex'|'pageSize'>) {
    const {
        keyword,
        customerCode,
        organizationUnitId,
        statusId,
        channelId,
        serviceTypeId,
    } = params;
    const { pageIndex, changePage, resetPage } = usePaging(params);
    const {
        data: ticketsInProgressResponse,
        error,
        isFetching,
        isLoading
    } = useGetTicketsInProgressByCustomerCodeQuery(
        {
            pageIndex: pageIndex,
            pageSize,
            keyword,
            customerCode,
            organizationUnitId,
            statusId,
            channelId,
            serviceTypeId
        },
    );
    const handleChangePage = changePage;

    return {
        ticketsInProgress: ticketsInProgressResponse?.data?.rows || [],
        count: ticketsInProgressResponse ? ticketsInProgressResponse.data?.count : 0,
        isLoading: isFetching || isLoading,
        pageIndex,
        handleChangePage,
        resetPage,
        error
    };
}