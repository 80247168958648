import { createApi } from '@reduxjs/toolkit/query/react';
import {
  ChangePasswordDto,
  ForgotPasswordDto,
  LoginDto,
  ResetPasswordDto,
  ResponseDto,
  TokenDto,
  VerifyForgotPasswordDataDto,
  VerifyForgotPasswordDto
} from 'types';
import { LoginSoftPhoneDto } from 'types/dto/softphone/login-softphone.dto';
import { LogoutSoftPhoneDto } from 'types/dto/softphone/logout-softphone.dto';
import { MakeCallSoftPhoneDto } from 'types/dto/softphone/makecall-softphone.dto';
import { TokenSoftPhone } from 'types/dto/softphone/token-softphone.dto';
import { TransferSoftPhoneDto } from 'types/dto/softphone/transfer-softphone.dto';
import axiosBaseQuery from 'utils/base-api';
import jsonpBaseQuery from 'utils/jsonp-base-api';

export const softPhoneApi = createApi({
  reducerPath: 'softPhoneApi',
  baseQuery: jsonpBaseQuery,
  endpoints: (builder) => ({
    loginSoftPhone: builder.mutation<ResponseDto<string>, LoginSoftPhoneDto>({
      query: (loginSoftPhoneDto) => ({
        url: 'http://127.0.0.1:4354/login',
        method: 'get',
        params: loginSoftPhoneDto,
        callbackName: 'wfappcallback' // Specify the callback name explicitly
      })
    }),
    logoutSoftPhone: builder.mutation<ResponseDto<string>, LogoutSoftPhoneDto>({
      query: (logoutSoftPhoneDto) => ({
        url: 'http://127.0.0.1:4354/logout',
        method: 'get',
        params: logoutSoftPhoneDto
      })
    }),
    answerSoftPhone: builder.mutation<ResponseDto<string>, TokenSoftPhone>({
      query: (token) => ({
        url: '/answer',
        method: 'get',
        params: token
      })
    }),
    hangUpSoftPhone: builder.mutation<ResponseDto<string>, TokenSoftPhone>({
      query: (token) => ({
        url: '/hangup',
        method: 'get',
        params: token
      })
    }),
    makeCallSoftPhone: builder.mutation<ResponseDto<string>, MakeCallSoftPhoneDto>({
      query: (makeCallDto) => ({
        url: '/makecall',
        method: 'get',
        params: makeCallDto
      })
    }),
    holdOnSoftPhone: builder.mutation<ResponseDto<string>, TokenSoftPhone>({
      query: (tokenSoftPhone) => ({
        url: '/hold_on',
        method: 'get',
        params: tokenSoftPhone
      })
    }),
    holdOffSoftPhone: builder.mutation<ResponseDto<string>, TokenSoftPhone>({
      query: (tokenSoftPhone) => ({
        url: '/hold_off',
        method: 'get',
        params: tokenSoftPhone
      })
    }),
    transferSoftPhone: builder.mutation<ResponseDto<string>, TransferSoftPhoneDto>({
      query: (transferSoftPhoneDto) => ({
        url: '/transfer',
        method: 'get',
        params: transferSoftPhoneDto
      })
    }),
    setMuteSoftPhone: builder.mutation<ResponseDto<string>, TransferSoftPhoneDto>({
      query: (transferSoftPhoneDto) => ({
        url: '/set_mute',
        method: 'get',
        params: transferSoftPhoneDto
      })
    })
  })
});

export const {
  useLoginSoftPhoneMutation,
  useLogoutSoftPhoneMutation,
  useAnswerSoftPhoneMutation,
  useHangUpSoftPhoneMutation,
  useHoldOnSoftPhoneMutation,
  useHoldOffSoftPhoneMutation,
  useTransferSoftPhoneMutation,
  useSetMuteSoftPhoneMutation
} = softPhoneApi;
