import { DatePicker, Form, Modal, Space, Spin, Timeline, Typography } from 'antd';
import { customizeRenderEmpty, InfiniteScroll, InputSearch } from 'components/common';
import RequestDetail from 'components/request-form/RequestDetail';
import dayjs from 'dayjs';
import { useSearchParamsForm } from 'hooks';
import { useTicketCreatedHistoryPaging, useTicketUserCreatedHistoryPaging } from 'hooks/tickets';
import { groupBy } from 'lodash';
import { messages, ticketMessages } from 'messages';
import { useState } from 'react';
import { colorsBase } from 'themes';
import { FindAllTicketCreatedHistoryDto } from 'types/dto/ticket';
import { TIME_STAMP_FORMAT, WEEKDAY_FORMAT } from 'utils';

type FindType = Omit<FindAllTicketCreatedHistoryDto, 'pageSize'>;
type Props = {
  userId: number;
};
const UserTicketCreatedHistoryTab = ({ userId }: Props) => {
  const { setSearchParams, getSearchParams } = useSearchParamsForm();

  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [openTicket, setOpenTicket] = useState<number>();

  const [form] = Form.useForm<FindType>();
  const values = Form.useWatch([], form) || getSearchParams();

  const { ticketCreatedHistories, pageIndex, count, handleChangePage, isLoading, resetPage } =
    useTicketUserCreatedHistoryPaging({
      ...values,
      userId
    });
  const groupedTicket = groupBy(ticketCreatedHistories, (o) => dayjs(o.createdAt).format(WEEKDAY_FORMAT));

  const handleLoadMore = () => {
    handleChangePage(pageIndex + 1);
  };

  const cancelDetailModal = () => {
    setDetailModalOpen(false);
  };

  const handleSelectTicket = (ticketId: number) => {
    setDetailModalOpen(true);
    setOpenTicket(ticketId);
  };

  return (
    <div>
      <div className='mb-5 flex items-center justify-between'>
        <Form
          scrollToFirstError={{ behavior: 'smooth', block: 'start' }}
          form={form}
          initialValues={getSearchParams()}
          onValuesChange={() => {
            resetPage();
            const values = form.getFieldsValue();
            setSearchParams(values);
          }}
        >
          <div>
            <div className='flex flex-1 gap-6'>
              <Form.Item<FindType> className='mb-0 max-w-[400px] flex-1' name='customerCode'>
                <InputSearch placeholder={ticketMessages.customerCode} />
              </Form.Item>

              <Form.Item<FindType> className='mb-0 max-w-[400px] flex-1' name='customerRequestPhoneNumber'>
                <InputSearch placeholder={ticketMessages.customerRequestPhoneNumber} />
              </Form.Item>
              <div className='flex items-center gap-2'>
                <Form.Item<FindType> noStyle name='fromDate'></Form.Item>
                <Form.Item<FindType> noStyle name='toDate'></Form.Item>
                <DatePicker.RangePicker
                  format={'DD/MM/YYYY'}
                  value={[
                    values.fromDate ? dayjs(values.fromDate, 'DD/MM/YYYY') : null,
                    values.toDate ? dayjs(values.toDate, 'DD/MM/YYYY') : null
                  ]}
                  placeholder={[ticketMessages.fromDate, ticketMessages.toDate]}
                  onChange={(dates) => {
                    form.setFieldsValue({
                      fromDate: dates && dates[0] ? dates[0].format('DD/MM/YYYY') : '',
                      toDate: dates && dates[1] ? dates[1].format('DD/MM/YYYY') : ''
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </Form>
      </div>
      {count === 0 && !isLoading ? (
        <div className='min-h-[calc(100%-220px)] bg-white'>{customizeRenderEmpty()}</div>
      ) : (
        <InfiniteScroll
          isLoading={isLoading}
          next={handleLoadMore}
          hasMore={(ticketCreatedHistories?.toLocaleString && ticketCreatedHistories?.length < count) ?? false}
          loader={<Spin spinning={isLoading}></Spin>}
          endMessage={<p></p>}
        >
          {Object.keys(groupedTicket).map((date) => (
            <div key={date} className='timeline-tab mb-2'>
              <Typography.Paragraph className='mb-4'>{date}</Typography.Paragraph>
              <Timeline
                items={groupedTicket[date].map((ticket) => ({
                  color: colorsBase.colorTextDescription,
                  dot: <div className='h-2 w-2 rounded-full bg-colorTextDescription' />,
                  children: (
                    <div
                      className='flex w-full cursor-pointer justify-between hover:bg-colorBgItemHover'
                      onClick={() => handleSelectTicket(ticket.ticketId)}
                    >
                      <Space>
                        <Typography.Text>{ticketMessages.create} </Typography.Text>
                        <Space split={<Typography.Text className='font-semibold'>-</Typography.Text>}>
                          <Typography.Text className='font-semibold'>{ticket.ticketId}</Typography.Text>
                          <Typography.Text className='font-semibold'>{ticket.serviceType?.name}</Typography.Text>
                        </Space>
                      </Space>
                      <Typography.Text type='secondary'>
                        {dayjs(ticket.createdAt).format(TIME_STAMP_FORMAT)}
                      </Typography.Text>
                    </div>
                  )
                }))}
              />
            </div>
          ))}
        </InfiniteScroll>
      )}

      <Modal
        centered
        className='ant-modal-body-p-none min-h-[1000px] w-fit rounded-xl bg-[#F5F4F6]'
        open={detailModalOpen}
        onCancel={cancelDetailModal}
        footer={null}
        destroyOnClose
      >
        {openTicket && <RequestDetail ticketId={openTicket} cancelDetailModal={cancelDetailModal} />}
      </Modal>
    </div>
  );
};
export default UserTicketCreatedHistoryTab;
